import { PrimaryButton, SecondButton } from 'atoms/Button';
import Modal from 'atoms/Modal';
import * as cMst from 'components/mst/Index';
import {
  initCompanyOrder,
  initCompanySearch,
  orderOptions,
} from 'const/comapny/index';
import log from 'functions/logger';
import React, { useState } from 'react';
import { tCompanySearch } from 'types/company';
import { tOrder } from 'types/index';

interface MainProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callback: (data: tCompanySearch, orders: tOrder[]) => void;
}
const Main = ({ open, setOpen, callback }: MainProps) => {
  const [values, setValues] = useState<tCompanySearch>(initCompanySearch);
  const [orders, setOrders] = useState<tOrder[]>([initCompanyOrder]);

  const handleClickSearch = () => {
    log.debug('handleClickSearch');
    callback(values, orders);
    handleCickClose();
  };

  const handleCickClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      title=""
      actions={
        <ActionBtn
          callbackSearch={handleClickSearch}
          callbackClose={handleCickClose}
        />
      }
      open={open}
      onClose={handleCickClose}
    >
      <cMst.Freewords values={values} setValues={setValues} />
      <cMst.Orders
        values={orders[0]}
        setValues={setOrders}
        no={0}
        options={orderOptions}
      />
    </Modal>
  );
};

export default Main;

interface ActionBtnProps {
  callbackSearch: () => void;
  callbackClose: () => void;
}
const ActionBtn = ({ callbackSearch, callbackClose }: ActionBtnProps) => {
  return (
    <>
      <PrimaryButton label="検索" onClick={callbackSearch} />
      <SecondButton onClick={callbackClose} label={'閉じる'} />
    </>
  );
};
