import { API_URL, create } from 'functions/axios';
import { tHistory } from 'types/history';
import { apiGetIndexProps } from 'types/index';

type getHistoriesProps = apiGetIndexProps & {
  callbackSuccess?: ({
    data,
    currentPage,
    lastPage,
    filter,
  }: {
    data: tHistory[];
    currentPage: number;
    lastPage: number;
    filter: any;
  }) => void | undefined;
};

export const getHistories = ({
  page,
  filter = {},
  order = [],
  callbackSuccess = undefined,
  callbackError = undefined,
}: getHistoriesProps) => {
  if (order.length === 0) {
    order = [{ field: 'created_at', direction: 'desc' }];
  }
  const ins = create();
  return ins
    .get(API_URL.base + '/history/index', {
      params: { page: page, filter: filter, order: order },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データ取得に失敗しました');
      }
      if (callbackSuccess) {
        callbackSuccess({
          data: res.data.data,
          currentPage: res.data.current_page,
          lastPage: res.data.last_page,
          filter: filter,
        });
      }
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError();
      }
      alert('データ取得に失敗しました');
    });
};
