import initInstruction from 'const/instruction';
import {
  destroyInstructions,
  storeInstructions,
} from 'functions/api/instruction';
import {
  changeDateToStrDay,
  mathDateTime,
  strDateOrigin,
} from 'functions/time';
import { useDrop } from 'react-dnd';
import * as typeInst from 'types/instruction';
import { tTransportCard, tTransportKey } from 'types/transport';

interface InsertProps {
  instructions: typeInst.tInstruction[];
  tranKey?: tTransportKey;
  callbackSuccess?: ((data: typeInst.tInstruction[]) => void) | undefined;
}

/**
 * tmp運行指示への登録処理
 * 対象がなければ削除になる
 * @param param0
 */
export const inserts = ({
  instructions,
  tranKey = { pj_id: 0, no: 0 },
  callbackSuccess = undefined,
}: InsertProps) => {
  const callback = (data: typeInst.tInstruction[]) => {
    if (callbackSuccess) {
      callbackSuccess(data);
    }
  };
  if (instructions.length === 0) {
    // 削除処理
    destroyInstructions(tranKey, callback);
  } else {
    // 登録
    storeInstructions(instructions, (data: typeInst.tInstruction[]) => {
      callback(data);
    });
  }
};

/**
 * ドロップ処理のハンドラ
 * @param day
 * @param callbackDrop
 * @param extraData
 * @returns
 */
export const useDropHandler = (
  day: string,
  callbackDrop: (item: typeInst.tInstruction) => void,
  extraData: Partial<typeInst.tInstruction> = {}
) => {
  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: 'TransportCard', // ドラッグ対象の種類
    drop: (item: tTransportCard) => {
      // ドロップされた時の処理
      const defInst: typeInst.tInstruction = {
        ...initInstruction,
        pj_id: item.pj_id,
        no: item.tran_no,
        start_datetime: `${day} 00:00:00`,
        ...extraData, // driver_id や tm_id を渡せるようにする
      };
      callbackDrop(defInst);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return { isOver, dropRef };
};

////////////////////////////////////// 簡単運行指示作成用 //////////////////////////////////////

/**
 * 直前の運行指示から開始情報を作成
 * @param datetime
 * @param lastInstruction
 * @param lastWaypoint
 * @returns
 */
const startData = (
  datetime: typeInst.MakeInstructionProps['datetime'],
  lastInstruction: typeInst.tInstruction,
  lastWaypoint?: typeInst.MakeInstructionProps['lastWaypoint']
) => {
  return {
    start_datetime: datetime,
    start_a_id: lastInstruction.end_a_id || null,
    start_name: lastInstruction.end_name || lastWaypoint?.a_name || '',
    start_prefectures:
      lastInstruction.end_prefectures || lastWaypoint?.prefectures || '',
    start_city: lastInstruction.end_city || lastWaypoint?.city || '',
    start_street: lastInstruction.end_street || lastWaypoint?.street || '',
    start_building:
      lastInstruction.end_building || lastWaypoint?.building || '',
    start_trailer_v_id: lastInstruction.end_trailer_v_id || null,
  };
};

/**
 * 宵積完走の運行指示を作成
 * @param comp
 */
export const makeCompEvening = ({
  defInstruction,
  datetime,
  startWaypoint,
  lastWaypoint,
  instructions,
  setInstructions,
}: typeInst.MakeInstructionProps) => {
  let lastInstruction = instructions[instructions.length - 1];
  if (lastInstruction === undefined) {
    lastInstruction = initInstruction;
  }
  const newInst = {
    ...initInstruction,
    ...defInstruction,

    // 開始情報は最終運行指示、または最初の積地情報とする
    ...startData(datetime, lastInstruction, startWaypoint),

    // 開始日時は前日に上書き
    start_datetime: changeDateToStrDay(
      mathDateTime(datetime, [0, 0, -1, 0, 0, 0])
    ),

    // 終了地点は
    end_datetime: datetime,
    end_a_id: lastWaypoint?.a_id || null,
    end_name: lastWaypoint?.a_name || '',
    end_prefectures: lastWaypoint?.prefectures || '',
    end_city: lastWaypoint?.city || '',
    end_street: lastWaypoint?.street || '',
    end_building: lastWaypoint?.building || '',
    end_trailer_v_id: lastInstruction.end_trailer_v_id || null,

    /*
    tm_id: lastInstruction.tm_id,
    user_id: lastInstruction.user_id,
    v_id: lastInstruction.v_id,
    c_id: lastInstruction.c_id,
    c_vehicle: lastInstruction.c_vehicle,
    price: lastInstruction.price,
    flg_picup: lastInstruction.flg_picup,
    instruction_datetime: lastInstruction.instruction_datetime,
    instruction_user_id: lastInstruction.instruction_user_id,
    */
  };

  setInstructions([...instructions, newInst]);

  return [...instructions, newInst];
};

/**
 * datetimeで完走の運行指示を作成
 * @param param0
 * @returns
 */
export const makeComplete = ({
  defInstruction,
  datetime,
  startWaypoint,
  lastWaypoint,
  instructions,
  setInstructions,
}: typeInst.MakeInstructionProps) => {
  let lastInstruction = instructions[instructions.length - 1];
  if (lastInstruction === undefined) {
    lastInstruction = initInstruction;
  }
  const newInst = {
    ...initInstruction,
    ...defInstruction,

    // 開始情報は最終運行指示、または最初の積地情報とする
    ...startData(datetime, lastInstruction, startWaypoint),

    // 終了地点は
    end_datetime: datetime,
    end_a_id: lastWaypoint?.a_id || null,
    end_name: lastWaypoint?.a_name || '',
    end_prefectures: lastWaypoint?.prefectures || '',
    end_city: lastWaypoint?.city || '',
    end_street: lastWaypoint?.street || '',
    end_building: lastWaypoint?.building || '',
    end_trailer_v_id: lastInstruction.end_trailer_v_id || null,
  };

  setInstructions([...instructions, newInst]);

  return [...instructions, newInst];
};

/**
 * 輸送手段を指定して運行指示を作成
 * @param param0
 * @returns
 */
export const makeTranMethoed = ({
  defInstruction,
  datetime,
  startWaypoint,
  instructions,
  setInstructions,
  tranMethoed,
}: typeInst.MakeTranMethoedProps) => {
  let lastInstruction = instructions[instructions.length - 1];
  if (lastInstruction === undefined) {
    lastInstruction = initInstruction;
  }

  // 着日時は輸送手段の開始時刻とする
  let endDatetime = datetime;
  if (tranMethoed.start_time) {
    endDatetime = strDateOrigin(datetime) + ' ' + tranMethoed.start_time;
  }

  const newInstDriver = {
    ...initInstruction,
    ...defInstruction,

    // 開始情報は最終運行指示、または最初の積地情報とする
    ...startData(datetime, lastInstruction, startWaypoint),

    // 終了地点
    end_datetime: endDatetime,
    end_a_id: tranMethoed?.start_a_id || null,
    end_name: tranMethoed?.start_name || '',
    end_prefectures: tranMethoed?.start_prefectures || '',
    end_city: tranMethoed?.start_city || '',
    end_street: tranMethoed?.start_street || '',
    end_building: tranMethoed?.start_building || '',
    end_trailer_v_id: lastInstruction.end_trailer_v_id || null,
  };

  // 開始時は輸送手段の開始時刻とする
  const newInstTranMethoedStartDatetime = endDatetime;

  // 着日時は輸送手段の終了時刻とする
  let newInstTranMethoedEndDatetime = datetime;
  if (tranMethoed.end_time) {
    if (
      tranMethoed.start_time &&
      tranMethoed.start_time > tranMethoed.end_time
    ) {
      // 日を跨ぐ移動
      const a = mathDateTime(
        newInstTranMethoedStartDatetime,
        [0, 0, 1, 0, 0, 0, 0]
      );
      const nextDay = changeDateToStrDay(a);
      newInstTranMethoedEndDatetime = nextDay + ' ' + tranMethoed.end_time;
    } else {
      newInstTranMethoedEndDatetime =
        strDateOrigin(newInstTranMethoedStartDatetime) +
        ' ' +
        tranMethoed.end_time;
    }
  }

  const newInstTranMethoed = {
    ...initInstruction,
    ...defInstruction,

    // 開始情報は最終運行指示、または最初の積地情報とする
    ...startData(datetime, newInstDriver),
    start_datetime: newInstTranMethoedStartDatetime,

    // 終了地点
    end_datetime: newInstTranMethoedEndDatetime,
    end_a_id: tranMethoed?.end_a_id || null,
    end_name: tranMethoed?.end_name || '',
    end_prefectures: tranMethoed?.end_prefectures || '',
    end_city: tranMethoed?.end_city || '',
    end_street: tranMethoed?.end_street || '',
    end_building: tranMethoed?.end_building || '',
    end_trailer_v_id: lastInstruction.end_trailer_v_id || null,

    // 輸送手段を設定し、ドライバーと車両はnull
    tm_id: tranMethoed.id,
    user_id: null,
    v_id: null,
  };

  setInstructions([...instructions, newInstDriver, newInstTranMethoed]);

  return [...instructions, newInstDriver, newInstTranMethoed];
};
