import { TableCell, TableRow, Typography } from '@mui/material';
import { DeleteButton, PrimaryButton } from 'atoms/Button';
import Loading from 'atoms/Loading';
import { GridItemCheckbox, GridItemRowTextField } from 'components/GridItem';
import * as PjBtn from 'components/project/Button';
import { initPjMemo } from 'const/project/index';
import { MstDataContext } from 'contexts/Mst';
import TableFrame from 'frames/TableFrame';
import * as apiPJ from 'functions/api/project';
import React, { useContext, useEffect } from 'react';
import { tPjMemo, tPjMemoSearch } from 'types/project';

export default function Main() {
  const [flgLoading, setFlgLoading] = React.useState<boolean>(true);

  return (
    <>
      <TableFrame
        HeadContent={<HeadContent flgLoading={flgLoading} />}
        TableHeaderRows={<TableHeaderRows />}
        TableBodyRows={<TableBodyRows setFlgLoading={setFlgLoading} />}
        SubContent={<></>}
      ></TableFrame>
    </>
  );
}

const HeadContent = ({ flgLoading }: { flgLoading: boolean }) => {
  return (
    <>
      <Loading flg={flgLoading} />
    </>
  );
};

const TableHeaderRows = () => {
  return (
    <TableRow>
      <TableCell>日時</TableCell>
      <TableCell>メモ</TableCell>
      <TableCell>フラグ</TableCell>
      <TableCell>機能</TableCell>
    </TableRow>
  );
};

function TableBodyRows({
  setFlgLoading,
}: {
  setFlgLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { loginUser } = useContext(MstDataContext);

  const [newMemo, setNewMemo] = React.useState<tPjMemo[]>([initPjMemo]);

  const [memos, setMemos] = React.useState<tPjMemo[]>([]);
  const [terms, setTerms] = React.useState<tPjMemoSearch>({
    insert_user_id: loginUser.id,
    flg_pj: false,
  });
  const [searchTrriger, setSearchTrriger] = React.useState<string>('');

  useEffect(() => {
    setFlgLoading(true);
    apiPJ.getPjMemoList({
      filter: terms,
      order: [{ field: 'created_at', direction: 'desc' }],
      callbackSuccess: ({ data, filter }) => {
        setMemos(data);
      },
    });
    setFlgLoading(false);
  }, [terms, searchTrriger]);

  useEffect(() => {
    setSearchTrriger(new Date().toISOString());
  }, []);

  const handleInsert = (memo: tPjMemo) => {
    apiPJ.storePjMemo(memo, cbsInsert);
  };

  const cbsInsert = () => {
    // 再検索を実行
    setSearchTrriger(new Date().toISOString());
    setNewMemo([initPjMemo]);
    setFlgLoading(false);
  };

  const handleUpdate = (memo: tPjMemo) => {
    setFlgLoading(true);
    apiPJ.updatePjMemo(memo, cbs, cbe);
  };

  const cbs = () => {
    // 再検索を実行
    setSearchTrriger(new Date().toISOString());

    setFlgLoading(false);
  };

  const cbe = () => {
    // 再検索を実行
    setSearchTrriger(new Date().toISOString());
    setFlgLoading(false);
  };

  const handleDelete = (memo: tPjMemo) => {
    setFlgLoading(true);
    apiPJ.deletePjMemo(memo.id, cbs, cbe);
  };

  return (
    <>
      {Array.from({ length: 1 }, (_, i) => {
        return (
          <TableRow key={`pjmemo-new-${i}`}>
            <TableCell>
              <Typography>新規</Typography>
            </TableCell>
            <TableCell>
              <GridItemRowTextField
                label=""
                name="memo"
                value={newMemo[i].memo}
                handleChangeValues={(
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  setNewMemo((prev) => {
                    const newMemo = [...prev];
                    newMemo[i] = { ...newMemo[i], memo: e.target.value };
                    return newMemo;
                  });
                }}
                row={2}
              />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <PrimaryButton
                label={'保存'}
                onClick={() => {
                  handleInsert(newMemo[i]);
                }}
              />
            </TableCell>
          </TableRow>
        );
      })}
      {memos.map((memo, i) => {
        return (
          <TableRow key={`pjmemo-${i}`}>
            <TableCell>
              <Typography>
                {new Date(memo.created_at || '').toLocaleString()}
              </Typography>
            </TableCell>
            <TableCell>
              <GridItemRowTextField
                label=""
                name="memo"
                value={memo.memo}
                handleChangeValues={(
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  setMemos((prev) => {
                    const newMemos = [...prev];
                    newMemos[i] = { ...newMemos[i], memo: e.target.value };
                    return newMemos;
                  });
                }}
                row={2}
              />
            </TableCell>
            <TableCell>
              <GridItemCheckbox
                labelId={`flg_pj-${i}`}
                label=""
                id={`flg_pj-${i}`}
                name="flg_pj"
                value={memo.flg_pj}
                handleChangeValues={(
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  setMemos((prev) => {
                    const newMemos = [...prev];
                    newMemos[i] = {
                      ...newMemos[i],
                      flg_pj: !newMemos[i].flg_pj,
                    };
                    return newMemos;
                  });
                }}
              />
            </TableCell>
            <TableCell>
              <PjBtn.Detail pj_id={0} label={'案件入力'} />
              <PrimaryButton
                label={'更新'}
                onClick={() => handleUpdate(memo)}
              />
              <DeleteButton label={'削除'} onClick={() => handleDelete(memo)} />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}

/*
const PjTable = () => {
  const [project, setProject] = React.useState<tProject>(initProject);
  const [waypoints, setWaypoints] = React.useState<tWaypoint[]>([
    { ...initialWaypoint, kbn: waypointKbnLoad.id },
    { ...initialWaypoint, kbn: waypointKbnUnload.id },
  ]);
  return (
    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>区分</TableCell>
            <TableCell>積日</TableCell>
            <TableCell>積地</TableCell>
            <TableCell>卸日</TableCell>
            <TableCell>卸地</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow onClick={() => setOpen(true)}>
            <TableCell>登録済みの区分</TableCell>
            <TableCell>登録済みの積日</TableCell>
            <TableCell>登録済みの積地</TableCell>
            <TableCell>登録済みの卸日</TableCell>
            <TableCell>登録済みの卸地</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <PComponent.Kind
                values={project}
                setValues={setProject}
                flgLabel={false}
              />
            </TableCell>
            <TableCell>
              <PComponent.LoadDate
                values={project}
                setValues={setProject}
                flgLabel={false}
              />
            </TableCell>
            <TableCell>
              <WComponent.Address
                index={0}
                waypoint={waypoints[0]}
                setValues={setWaypoints}
                //onChange={WComponent.handleWaypointsChange}
                flgBtn={false}
              />
            </TableCell>
            <TableCell>
              <PComponent.UnloadDate
                values={project}
                setValues={setProject}
                flgLabel={false}
              />
            </TableCell>
            <TableCell>
              <WComponent.Address
                index={1}
                waypoint={waypoints[1]}
                setValues={setWaypoints}
                //onChange={WComponent.handleWaypointsChange}
                flgBtn={false}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};
*/
