import { tLuggage, tLuggageSaerch } from 'types/mst';

export const initLuggage: tLuggage = {
  id: 0,
  name: '',
  unit: '',
  volume: 0,
  cm_ids: [],
};
export const initSearchLuggage: tLuggageSaerch = {};
