import * as cCommon from 'components/Common';
import React from 'react';
import { tSelfInformation } from 'types/mst';

interface GridItemFieldProps {
  values: tSelfInformation;
  setValues: React.Dispatch<React.SetStateAction<tSelfInformation>>;
}
export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="会社名"
    name="name"
    value={values.name}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Tel = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="電話番号"
    name="tel"
    value={values.tel}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Fax = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="FAX"
    name="fax"
    value={values.fax}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const CorporateNumber = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="法人番号"
    name="corporate_number"
    value={values.corporate_number}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Address1 = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="住所"
    name="address1"
    value={values.address1}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Address2 = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="建物名など"
    name="address2"
    value={values.address2}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const PostNumber = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="郵便番号"
    name="postal_code"
    value={values.postal_code}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const BillingInfo = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemRowTextFiled
    label="口座情報"
    name="billing_info"
    value={values.billing_info}
    setValues={setValues}
    size={{ xs: 12, lg: 6, xl: 4 }}
  />
);

export const Notes = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemRowTextFiled
    label="備考"
    name="notes"
    value={values.notes}
    setValues={setValues}
    size={{ xs: 12, lg: 6, xl: 4 }}
  />
);
