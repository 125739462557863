import { FormControl, Grid, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SmallTextField } from 'atoms/TextField';
import { NoSelect } from 'const/index';
import { getGridSize } from 'functions/index';
import React from 'react';
import { List, ListItem } from 'types/index';

interface SelectProps {
  labelId: string;
  id: string;
  items: List;
  selectedValue: number | bigint | null;
  onChange: (
    event: SelectChangeEvent<number | string>,
    child: React.ReactNode
  ) => void;
  flgNoSelect?: boolean;
}

export function SmallSelect({
  labelId,
  id,
  items,
  selectedValue,
  onChange,
  flgNoSelect = false,
}: SelectProps) {
  const safeValue = (value: any) => {
    const num = Number(value);
    return isNaN(num) ? 0 : num; // NaNならデフォルト値-1を返す
  };

  return (
    <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
      <Select
        labelId={labelId}
        id={id}
        name={id}
        value={safeValue(selectedValue)}
        onChange={onChange}
        fullWidth={true}
      >
        {flgNoSelect && (
          <MenuItem value={NoSelect.id}>{NoSelect.label}</MenuItem>
        )}
        {items.map((item) => (
          <MenuItem
            key={`${labelId}-${item.id ? item.id.toString() : 'init'}`}
            value={Number(item.id)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

interface GridItemSelectProps {
  labelId: string;
  id: string;
  label: string;
  selectedValue: number;
  items: List;
  handleChangeValues: (
    event: SelectChangeEvent<number | string>,
    child: React.ReactNode
  ) => void;
  size?: Record<string, number> | null;
}
export function GridItemSelect({
  labelId,
  id,
  label,
  selectedValue,
  items,
  handleChangeValues,
  size = undefined,
}: GridItemSelectProps) {
  const [xs, sm, md, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Typography variant="h6">{label}</Typography>
      <SmallSelect
        labelId={labelId}
        id={id}
        items={items}
        selectedValue={selectedValue}
        onChange={handleChangeValues}
      />
    </Grid>
  );
}

interface AutocompleteSelectProps {
  name: string;
  disableClearable?: boolean;
  readOnly?: boolean;
  label: string;
  options: Record<string, any>[];
  value: Record<string, any> | null;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: ListItem
  ) => void;
}
export const AutocompleteSelect = ({
  name,
  disableClearable = true,
  readOnly = false,
  label,
  options,
  value,
  onChange,
}: AutocompleteSelectProps) => {
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <Autocomplete
        sx={{ padding: 0, width: '100%' }}
        getOptionLabel={(option) =>
          option.label ? String(option.label) : String(option.name)
        }
        value={value}
        onChange={(e, newValue) => onChange(e, newValue)}
        options={options}
        disableClearable={disableClearable}
        readOnly={readOnly}
        renderInput={(params) => (
          <SmallTextField label={undefined} props={params} />
        )}
        limitTags={5} // 最大表示数を5件に制限
      />
    </>
  );
};
