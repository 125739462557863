import { Box, Typography } from '@mui/material';
import { DateInput } from 'atoms/DateInput';
import Loading from 'atoms/Loading';
import {
  ItemAutoComplete,
  ItemCheckbox,
  ItemNumberField,
  ItemSelect,
  ItemTextFiled,
} from 'components/Common';
import { MstDataContext } from 'contexts/Mst';
import log from 'functions/logger';
import { strDateCustom, strTimeOrigin } from 'functions/time';
import React, { useContext, useEffect } from 'react';
import { ItemFieldProps, ItemSelectProps, List } from 'types/index';
import { tInstruction } from 'types/instruction';
import { tTransportMethod } from 'types/mst';

interface GridItemFieldProps {
  name?: string;
  values: tInstruction;
  setValues: React.Dispatch<React.SetStateAction<tInstruction>>;
  size?: Record<string, number>;
}

export const SelectMethods = React.memo(
  ({ values, setValues, size = undefined }: GridItemFieldProps) => {
    const { loading, tranMethods } = useContext(MstDataContext);

    const options = tranMethods?.map((method: tTransportMethod) => ({
      id: method.id,
      label: method.name,
    })) as List;

    if (loading || !tranMethods) {
      return <Loading flg={true} />;
    }

    //log.debug('values.tm_id', values.tm_id);

    return (
      <ItemSelect
        labelId="tmId-label"
        name="tm_id"
        label="輸送方法"
        value={values.tm_id}
        items={options}
        setValues={setValues}
        size={size}
      />
    );
  }
);

/**
 * ドライバー選択
 * ※実業務では車両を選択するが、本システムではドライバーを選択することで
 * その日ドライバーにも付いている車両を選択する
 * @param param0
 * @returns
 */
export const Driver = React.memo(
  ({ values, setValues }: GridItemFieldProps) => {
    const { loading, users, drivers, vehicles } = useContext(MstDataContext);

    const selectedDriver = drivers?.find(
      (driver) => driver.id === values.user_id
    );
    const selectedVehicle = vehicles?.find(
      (vehicle) => vehicle.id === values.v_id
    );

    const [vehicleOptions, setVehicleOptions] = React.useState<List>([]);
    useEffect(() => {
      if (vehicles) {
        setVehicleOptions(
          vehicles.map((obj) => ({
            id: obj.id,
            label: obj.plate,
          }))
        );
      }
    }, [vehicles]);

    /**
     * ドライバーが変更された場合の処理
     */
    useEffect(() => {
      if (!users || values.user_id) {
        return;
      }

      const dv = users.find((user) => user.id === values.user_id);
      if (dv) {
        setValues({
          ...values,
          v_id: dv.v_id,
        });
      }
    }, [values.user_id, users]);

    //log.debug("selectedDriver", selectedDriver);

    if (loading || !drivers) {
      return <Loading flg={true} />;
    }

    return (
      <>
        <ItemSelect
          labelId="user-label"
          name="user_id"
          label="ドライバー"
          value={selectedDriver ? Number(selectedDriver.id) : 0}
          items={drivers}
          setValues={setValues}
        />
        <ItemSelect
          labelId="vehicle-label"
          name="v_id"
          label="車番"
          value={selectedVehicle ? Number(selectedVehicle.id) : 0}
          items={vehicleOptions}
          setValues={setValues}
        />
      </>
    );
  }
);

/**
 * シャーシ選択
 * @param param0
 * @returns
 */
export const Trailer = React.memo(
  ({
    label = '自社シャーシ',
    name,
    value,
    setValues,
    size,
  }: ItemSelectProps) => {
    const { loading, trailer } = useContext(MstDataContext);

    if (!size) {
      size = { xs: 12, sm: 4, md: 3, lg: 2, xl: 2 };
    }

    const selected = trailer?.find((obj) => obj.id === value);

    const [options, setOptions] = React.useState<List>([]);

    useEffect(() => {
      if (trailer) {
        const trailers = trailer.map((obj) => ({
          id: obj.id,
          label: obj.plate,
        }));
        setOptions([{ id: 0, label: '(空)' }, ...trailers]);
      }
    }, [trailer]);

    //log.debug("selectedDriver", selectedDriver);

    if (loading || !trailer) {
      return <Loading flg={true} />;
    }

    return (
      <ItemSelect
        labelId={`${name}-label`}
        name={name}
        label={label}
        value={selected ? Number(selected.id) : 0}
        items={options}
        setValues={setValues}
        size={size}
      />
    );
  }
);

/**
 * シャーシメモ
 * @param param0
 * @returns
 */
export const TrailerMemo = React.memo(
  ({
    label = 'シャーシ備考',
    name,
    value,
    setValues,
    size,
  }: ItemFieldProps) => {
    if (!size) {
      size = { xs: 12, sm: 8, md: 9, lg: 4, xl: 4 };
    }

    return (
      <ItemTextFiled
        name={name}
        label={label}
        value={value}
        setValues={setValues}
        size={size}
      />
    );
  }
);

/**
 * 発日時
 * @param param0
 * @returns
 */
export const StartDatetime = ({ values, setValues }: GridItemFieldProps) => {
  const handleDateChange = (newDate: string) => {
    //log.debug('newDate', newDate);

    // 年月日のみを更新するため、時刻は元の値を使用
    const strDatetime = `${newDate} ${strTimeOrigin(values.start_datetime)}`;

    // 入力日のに設定
    const inputDateTime = new Date(strDatetime);
    if (inputDateTime.toString() === 'Invalid Date') {
      return;
    }
    setValues({
      ...values,
      start_datetime: strDatetime,
    });
  };
  return (
    <Box>
      <Typography variant="h6">発日</Typography>
      <DateInput
        type="date"
        value={strDateCustom(values.start_datetime)}
        name="start_datetime"
        onDateChange={handleDateChange}
        showSpinButtons={true} // スピンボタンのオンオフを制御
      />
    </Box>
  );
};

/**
 * 着日時
 * @param param0
 * @returns
 */
export const EndDatetime = ({ values, setValues }: GridItemFieldProps) => {
  const handleDateChange = (newDate: string) => {
    //log.debug('newDate', newDate);

    const strDatetime = `${newDate} ${strTimeOrigin(values.end_datetime)}`;
    // 入力日のに設定
    const inputDateTime = new Date(strDatetime);
    if (inputDateTime.toString() === 'Invalid Date') {
      return;
    }
    setValues({
      ...values,
      end_datetime: strDatetime,
    });
  };

  useEffect(() => {
    log.debug('values', values);
  }, [values.end_datetime]);
  return (
    <Box>
      <Typography variant="h6">着日</Typography>
      <DateInput
        type="date"
        value={strDateCustom(values.end_datetime)}
        name="end_datetime"
        onDateChange={handleDateChange}
        showSpinButtons={true} // スピンボタンのオンオフを制御
      />
    </Box>
  );
};

/**
 * 会社選択
 * @param param0
 * @returns
 */
export const ClientCompany = ({
  values,
  setValues,
  size = { xs: 12, lg: 3, xl: 3 },
}: GridItemFieldProps) => {
  const { loading, companies } = useContext(MstDataContext);
  if (loading) {
    return <div>Loading...</div>;
  }

  const options = companies?.map((company) => ({
    id: company.id,
    label: company.name,
  })) as List;

  const selectedOption = options?.find((option) => option.id === values.c_id);

  return (
    <ItemAutoComplete
      disableClearable={false}
      setValues={setValues}
      name="c_id"
      label="取引先"
      options={options}
      selectedOption={selectedOption}
      size={size}
    />
  );
};

/**
 * 傭車先車両情報
 * @param param0
 * @returns
 */
export const ClientVehicle = ({
  values,
  setValues,
  size = { xs: 12, lg: 3, xl: 3 },
}: GridItemFieldProps) => {
  return (
    <ItemTextFiled
      name="c_start_vehicle"
      label="車両情報"
      value={values.c_vehicle}
      setValues={setValues}
      size={size}
    />
  );
};

/**
 * 傭車先料金
 * @param param0
 * @returns
 */
export const ClientPrice = ({
  values,
  setValues,
  size = { xs: 12, lg: 3, xl: 3 },
}: GridItemFieldProps) => {
  return (
    <ItemNumberField
      name="price"
      label="金額"
      value={values.price}
      setValues={setValues}
      size={size}
    />
  );
};

/**
 * ピックアップ運行フラグ 手当に使用する
 * @param param0
 * @returns
 */
export const PickUp = ({
  values,
  setValues,
  size = { xs: 12, lg: 3, xl: 3 },
}: GridItemFieldProps) => {
  return (
    <ItemCheckbox
      name="flg_picup"
      label="ピックアップ運行"
      value={values.flg_picup}
      setValues={setValues}
      size={size}
    />
  );
};
