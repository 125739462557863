import { TableCell, TableRow } from '@mui/material';
import { SecondButton } from 'atoms/Button';
import PageNation from 'components/Pagenation';
import * as BtnTransportMethod from 'components/transportMethod/Button';
import { initSearchTransportMethod } from 'const/transportMethod';
import { MstDataContext } from 'contexts/Mst';
import TableFrame from 'frames/TableFrame';
import * as apiMst from 'functions/api/mst';
import { endLabel, startLabel } from 'functions/transportMethod';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiGetIndexSuccess } from 'types/index';
import { tTransportMethod, tTransportMethodSearch } from 'types/mst';

export default function Main() {
  const [list, setList] = useState<tTransportMethod[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [search, setSearch] = useState<tTransportMethodSearch>(
    initSearchTransportMethod
  );

  const cbs = ({ data, currentPage, lastPage, filter }: apiGetIndexSuccess) => {
    setList(data);
    setCurrentPage(currentPage);
    setLastPage(lastPage);
    setSearch(filter);
  };

  // 検索処理
  const getSearch = (page: number) => {
    try {
      // ここにデータ取得処理を記述します
      apiMst.getTransportMethods({
        page: page,
        filter: search,
        callbackSuccess: cbs,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSearch(currentPage);
  }, []);

  return (
    <TableFrame
      HeadContent={
        <HeadContent
          setData={setList}
          setCurrentPage={setCurrentPage}
          setLastPage={setLastPage}
          setFilter={setSearch}
        />
      }
      TableHeaderRows={<TableHeaderRows />}
      TableBodyRows={<TableBodyRows list={list} />}
      SubContent={
        <SubContent
          lastPages={lastPage}
          currentPage={currentPage}
          getSearch={getSearch}
        />
      }
    />
  );
}

interface HeadContentProps {
  setData: React.Dispatch<React.SetStateAction<tTransportMethod[]>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setLastPage: React.Dispatch<React.SetStateAction<number>>;
  setFilter: React.Dispatch<React.SetStateAction<tTransportMethodSearch>>;
}
const HeadContent = (props: HeadContentProps) => {
  const navigate = useNavigate();
  return (
    <>
      <BtnTransportMethod.SearchBtn {...props} />
      <SecondButton
        onClick={() => {
          navigate('/mst/transport-method/input');
        }}
        label="新規"
      />
    </>
  );
};

const TableHeaderRows = () => {
  return (
    <TableRow>
      <TableCell>名称</TableCell>
      <TableCell>開始地点</TableCell>
      <TableCell>終了地点</TableCell>
    </TableRow>
  );
};

interface TableBodyRowsProps {
  list: tTransportMethod[];
}
const TableBodyRows = ({ list }: TableBodyRowsProps) => {
  const navigate = useNavigate();
  const { SYSTEM } = useContext(MstDataContext);
  return (
    <>
      {list.map((tranMethod: tTransportMethod) => {
        if (tranMethod.domain === SYSTEM?.domain) return null;
        return (
          <TableRow
            key={`tranMethod-${tranMethod.id}`}
            onDoubleClick={() =>
              navigate(`/mst/transport-method/edit/${tranMethod.id}`)
            }
          >
            <TableCell>{tranMethod.name}</TableCell>
            <TableCell>
              <li>{`${tranMethod.start_time}`}</li>
              <li>{`${startLabel(tranMethod)}`}</li>
            </TableCell>
            <TableCell>
              <li>{`${tranMethod.end_time}`}</li>
              <li>{`${endLabel(tranMethod)}`}</li>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

interface SubContentProps {
  lastPages: number;
  currentPage: number;
  getSearch: (page: number) => void;
}
const SubContent = ({ lastPages, currentPage, getSearch }: SubContentProps) => {
  return (
    <PageNation
      totalPages={lastPages}
      currentPage={currentPage}
      handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
        getSearch(value)
      }
    />
  );
};
