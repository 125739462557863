import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { PrimaryButton } from 'atoms/Button';
import PageNation from 'components/Pagenation';
import { getVehicleTypes } from 'functions/api/mst';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tVehicleType } from 'types/mst';

export default function Main() {
  const navigate = useNavigate();
  const [vehicleTypes, setVehicleTypes] = useState<tVehicleType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getSearch = async (page: number) => {
    try {
      getVehicleTypes(page)
        .then((res) => {
          if (res.status !== 200) throw new Error('データ取得に失敗しました');
          setVehicleTypes(res.data.data);
          setCurrentPage(res.data.current_page);
          setTotalPages(res.data.last_page);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getSearch(currentPage);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={4} md={4} lg={2}>
            <PrimaryButton
              onClick={() => {
                navigate('/mst/vehicleType/input');
              }}
              label="新規"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table sx={{ maxWidth: '600px' }}>
            <TableHead>
              <TableRow>
                <TableCell>名称</TableCell>
                <TableCell>説明文</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vehicleTypes.map((vt) => {
                return (
                  <TableRow
                    key={`vehicleTypes-${vt.id}`}
                    onDoubleClick={() =>
                      navigate(`/mst/vehicleType/edit/${vt.id}`)
                    }
                  >
                    <TableCell>{vt.name}</TableCell>
                    <TableCell>{vt.description}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <PageNation
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
            getSearch(value)
          }
        />
      </Grid>
    </Grid>
  );
}
