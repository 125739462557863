import { tTransport, tTransportCard } from 'types/transport';

const initTransport: tTransport = {
  id: null,
  pj_id: null,
  no: null,
  status: null,
  container_code: '',
};

export default initTransport;

export const initialTransportCard: tTransportCard = {
  id: null, // tTransport['id'] の型に基づいて適切なデフォルト値を設定（文字列の場合は空文字）
  pj_id: 0,
  tran_no: 0,
  tran_status: 0,
  status: 0,
  container_code: '',
  kind: 0,
  kind_name: '',
  c_id: 0,
  company_name: '',
  company_abbreviation: '',
  load_datetime: '', // 日付の初期値は空文字列
  load_a_id: 0,
  load_name: '',
  load_abbreviation: '',
  unload_datetime: '',
  unload_a_id: 0,
  unload_name: '',
  unload_abbreviation: '',
  price: 0,
  sum_price_other: 0,
  waypoint_count: 0,
  warning_level: 0,
};
