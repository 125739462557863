import { Grid, TextField, Typography } from '@mui/material';
import { ItemSelect } from 'components/Common';
import { GridItemDateField, GridItemTextField } from 'components/GridItem';
import { MstDataContext } from 'contexts/Mst';
import { handleChangeValues } from 'functions/handles';
import { changeDateToStrDay, strDateCustom } from 'functions/time';
import React, { useContext } from 'react';
import { List, typeHashValue } from 'types/index';
import { tVehicle } from 'types/mst';

interface ItemFieldProps {
  label: string;
  name: string;
  value: typeHashValue;
  setValues: React.Dispatch<React.SetStateAction<tVehicle>>;
  size?: Record<string, number> | null;
}
const ItemTextFiled = ({
  label,
  name,
  value,
  setValues,
  size,
}: ItemFieldProps) => (
  <GridItemTextField
    label={label}
    value={value}
    name={name}
    handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
      handleChangeValues(e.target.value, e.target.name, setValues)
    }
    size={size}
  />
);

const ItemDateFiled = ({
  label,
  name,
  value,
  setValues,
  size,
}: ItemFieldProps) => (
  <GridItemDateField
    label={label}
    value={value as string}
    name={name}
    handleChangeValues={(newDate: string) =>
      handleChangeValues(newDate, name, setValues)
    }
    size={size}
  />
);

interface GridItemFieldProps {
  values: tVehicle;
  setValues: React.Dispatch<React.SetStateAction<tVehicle>>;
}
export const Id = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="車番"
    name="id"
    value={values.id}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Plate = ({ values, setValues }: GridItemFieldProps) => (
  <Grid item xs={12}>
    <Typography>車番</Typography>
    <div style={{}}>
      <TextField
        size="small"
        label="支局"
        value={values.plate_branch}
        name="plate_branch"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e.target.value, e.target.name, setValues)
        }
      />
      <TextField
        size="small"
        label="種別"
        value={values.plate_kind}
        name="plate_kind"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e.target.value, e.target.name, setValues)
        }
      />
      <TextField
        size="small"
        label="ひらがな"
        value={values.plate_hiragana}
        name="plate_hiragana"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e.target.value, e.target.name, setValues)
        }
      />
      <TextField
        size="small"
        label="ナンバー"
        value={values.plate_number}
        name="plate_number"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e.target.value, e.target.name, setValues)
        }
      />
    </div>
  </Grid>
);

/*
export const CmId = ({ values, setValues }: GridItemFieldProps) => {
  const { loading, carModels } = useContext(MstDataContext);
  if (loading || !carModels) return <Typography>loading...</Typography>;

  const options = carModels.map((cm) => ({
    id: cm.id,
    label: cm.name,
  }));
  const selectedOption = options.find(
    (option: any) => option.id === values.cm_id
  );

  return (
    <GridItemTextFieldCustom size={{ xs: 12, lg: 3, xl: 2 }}>
      <AutocompleteSelect
        name="cm_id"
        label={"車種"}
        options={options}
        value={selectedOption || null}
        onChange={(e: React.ChangeEvent<{}>, newValue: ListItem) =>
          handleChangeValues(newValue.id, "cm_id", setValues)
        }
      />
    </GridItemTextFieldCustom>
  );
};
*/

export const VehicleType = ({ values, setValues }: GridItemFieldProps) => {
  const { loading, vehicleTypes } = useContext(MstDataContext);
  if (loading) {
    return <div>Loading...</div>;
  }

  const options = vehicleTypes?.map((vt) => ({
    id: vt.id,
    label: vt.name,
  })) as List;

  options.push({ id: 0, label: 'その他' });

  return (
    <ItemSelect
      labelId="vt_id-label"
      name="vt_id"
      label="車種"
      value={Number(values.vt_id)}
      items={options}
      setValues={setValues}
    />
  );
};
export const Inspection = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemDateField
    label="車検日"
    name="inspection"
    value={changeDateToStrDay(values.inspection)}
    handleChangeValues={(newDate: string) =>
      handleChangeValues(strDateCustom(newDate), 'inspection', setValues)
    }
    size={{ xs: 12, lg: 4, xl: 3 }}
  />
);
