import { Grid } from '@mui/material';
import * as cRole from 'components/mst/Role';
import * as BtnRole from 'components/role/Button';
import { initialRole } from 'const/role';
import ContentsFrame from 'frames/ContentsFrame';
import { Console_log } from 'functions';
import { getRole } from 'functions/api/mst';
import log from 'functions/logger';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { tRole } from 'types/mst';

export default function Main() {
  const { id } = useParams();
  const [role, setRole] = useState<tRole>(initialRole);

  Console_log('id', id);

  const getSearch = async (id: number) => {
    try {
      // 住所データ取得
      getRole(id).then((res) => {
        if (res.status !== 200) throw new Error('データ取得に失敗しました');
        Console_log('role.data', res.data);
        setRole(res.data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getSearch(Number(id));
    }
  }, [id]);

  log.debug('role', role);

  return (
    <ContentsFrame
      HeadContent={<HeadContent id={Number(id)} values={role} />}
      MainContent={<Details values={role} setValues={setRole} />}
      SubContent={<></>}
    />
  );
}

interface HeadContentProps {
  id: tRole['id'];
  values: tRole;
}
const HeadContent = ({ id, values }: HeadContentProps) => {
  return (
    <>
      {id ? (
        <>
          <BtnRole.Update data={values} />
          <BtnRole.Delete id={values.id} />
        </>
      ) : (
        <BtnRole.Store data={values} />
      )}
    </>
  );
};

interface DetailsProps {
  values: tRole;
  setValues: React.Dispatch<React.SetStateAction<tRole>>;
}
const Details = ({ values, setValues }: DetailsProps) => {
  return (
    <>
      <Grid container spacing={2}>
        <cRole.Name values={values} setValues={setValues} />
      </Grid>
      <Grid container spacing={2}>
        <cRole.FlgAdmin values={values} setValues={setValues} />
        <cRole.FlgProject values={values} setValues={setValues} />
        <cRole.FlgOperation values={values} setValues={setValues} />
        <cRole.FlgPaperwork values={values} setValues={setValues} />
        <cRole.FlgMst values={values} setValues={setValues} />
        <cRole.FlgDriver values={values} setValues={setValues} />
      </Grid>
    </>
  );
};
