/**
 * 入力項目別のコンポーネントを作成する
 */
import Typography from '@mui/material/Typography';
import { FlexBox } from 'atoms/Box';
import Loading from 'atoms/Loading';
import { AutocompleteSelect } from 'atoms/Select';
import { SmallTextField } from 'atoms/TextField';
import {
  ItemDatetime,
  ItemNumberField,
  ItemSelect,
  ItemTextFiled,
} from 'components/Common';
import { GridItemTextFieldCustom } from 'components/GridItem';
import { MstDataContext } from 'contexts/Mst';
import { handleChangeValues } from 'functions/handles';
import log from 'functions/logger';
import React, { useContext } from 'react';
import { List, ListItem } from 'types';
import { tCustomInvoiceEdit } from 'types/invoice';

interface GridItemFieldProps {
  values: tCustomInvoiceEdit;
  setValues: React.Dispatch<React.SetStateAction<tCustomInvoiceEdit>>;
  size?: Record<string, number>;
}

/**
 * 日付
 * @param param0
 * @returns
 */
export const Date = ({ values, setValues }: GridItemFieldProps) => (
  <ItemDatetime
    label="日付"
    name="date"
    value={values.date}
    setValues={setValues}
    type={'date'}
  />
);

/**
 * 品名
 * @param param0
 * @returns
 */
export const Label = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="品名"
    name="label"
    value={values.label}
    setValues={setValues}
  />
);

export const ClientCompany = ({
  values,
  setValues,
  size = { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 },
}: GridItemFieldProps) => {
  const { loading, companies } = useContext(MstDataContext);
  if (loading) {
    return <div>Loading...</div>;
  }

  const options = companies?.map((company) => ({
    id: company.id,
    label: company.name,
  })) as List;

  const selectedOption = options?.find((option) => option.id === values.c_id);

  return (
    <GridItemTextFieldCustom size={size}>
      <Typography variant="h6">取引先</Typography>
      <FlexBox gapSize={1}>
        <SmallTextField
          label={''}
          props={{
            name: 'c_id',
            value: values.c_id || '',
            sx: { width: '100px' },
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeValues(Number(e.target.value), 'c_id', setValues);
            },
          }}
        />
        <AutocompleteSelect
          disableClearable={false}
          name="c_id"
          label={''}
          options={options}
          value={selectedOption || null}
          onChange={(
            e: React.SyntheticEvent<Element, Event>,
            newValue: ListItem
          ) => {
            if (!newValue) {
              handleChangeValues(null, 'c_id', setValues);
              return;
            }
            handleChangeValues(newValue.id, 'c_id', setValues);
          }}
        />
      </FlexBox>
    </GridItemTextFieldCustom>
  );
};

/**
 * 数量
 * @param param0
 * @returns
 */
export const Quantity = ({ values, setValues }: GridItemFieldProps) => (
  <ItemNumberField
    label="数量"
    name="quantity"
    value={values.quantity}
    setValues={setValues}
  />
);

/**
 * 単位
 * @param param0
 * @returns
 */
export const Unit = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="単位"
    name="unit"
    value={values.unit}
    setValues={setValues}
  />
);

/**
 * 単価
 * @param param0
 * @returns
 */
export const UnitPrice = ({ values, setValues }: GridItemFieldProps) => (
  <ItemNumberField
    label="単価"
    name="unit_price"
    value={values.unit_price}
    setValues={setValues}
  />
);

/**
 * 金額
 * @param param0
 * @returns
 */
export const Price = ({ values, setValues }: GridItemFieldProps) => (
  <ItemNumberField
    label="金額"
    name="price"
    value={values.price}
    setValues={setValues}
    disabled={true}
  />
);

/**
 * 税率
 * @param param0
 * @returns
 */
export const Tax = ({ values, setValues }: GridItemFieldProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  if (!SYSTEM) {
    return <Loading flg={true} />;
  }

  const options = SYSTEM.tax.list.map((item) => ({
    id: item.id,
    label: item.label,
  }));

  log.debug('Tax', 'values.tax_id', values.tax_id);

  return (
    <ItemSelect
      label="税率"
      name="tax_id"
      value={values.tax_id}
      setValues={setValues}
      items={options}
    />
  );
};

/**
 * 税込金額
 * @param param0
 * @returns
 */
export const PriceTax = ({ values, setValues }: GridItemFieldProps) => (
  <ItemNumberField
    label="税額"
    name="price_tax"
    value={values.price_tax}
    setValues={setValues}
    disabled={true}
  />
);

/**
 * 税込金額
 * @param param0
 * @returns
 */
export const PriceTaxInclude = ({ values, setValues }: GridItemFieldProps) => (
  <ItemNumberField
    label="税込金額"
    name="price_tax_include"
    value={values.price_tax_include}
    setValues={setValues}
    disabled={true}
  />
);

/**
 * メモ
 * @param param0
 * @returns
 */
export const Memo = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="メモ"
    name="memo"
    value={values.memo}
    setValues={setValues}
  />
);
