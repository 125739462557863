import { API_URL, create } from 'functions/axios';
import { tDailyMemo } from 'types/dailymemo';

/**
 * デイリーメモリスト
 * @returns
 */
export function getDailyMemoList(filter: any = {}, order: any = []) {
  if (order.length === 0) {
    order = [
      { field: 'datetime', direction: 'asc' },
      { field: 'id', direction: 'asc' },
    ];
  }
  const ins = create();
  return ins.get(API_URL.base + '/dailyMemo/list', {
    params: { filter: filter, order: order },
  });
}

/**
 *
 * @param data
 * @returns
 */
export function storeDailyMemo(data: any = {}) {
  const ins = create();
  return ins.post(API_URL.base + '/dailyMemo/store', data);
}

/**
 *
 * @param data
 * @returns
 */
export function deleteDailyMemo(id: tDailyMemo['id']) {
  const ins = create();
  return ins.post(API_URL.base + `/dailyMemo/destroy/${id}`);
}
