import Loading from 'atoms/Loading';
import { isAuthenticated } from 'functions/api/authenticates';
import React from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = React.useState<boolean | null>(null); // 初期状態はnull

  React.useEffect(() => {
    const checkAuth = async () => {
      const valid = await isAuthenticated();
      setIsAuth(valid);

      if (!valid) {
        // トークンが無効ならログアウト処理
        localStorage.removeItem('token');
        navigate('/login'); // リダイレクト
      }
    };

    checkAuth();
  }, [navigate]);

  if (isAuth === null) return <Loading />; // ローディング中はローディングコンポーネントを表示

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default ProtectedRoute;
