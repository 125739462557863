import { Box, Button, Container, Grid, Typography } from '@mui/material';
import BaseCard, { CardContents } from 'atoms/Card';
import Toolchip from 'atoms/Toolchip';
import { MstDataContext } from 'contexts/Mst';
import { useSelection } from 'contexts/Schedules';
import { truncateString } from 'functions/index';
import {
  checkTranMethod,
  getTranVehicleToTrailer,
} from 'functions/instruction/index';
import { diffDayCount, formatMonthDay, isSameDate } from 'functions/time';
import { checkInTransportCard } from 'functions/transport';
import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useNavigate } from 'react-router-dom';
import { cardType } from 'types/index';
import { tTransportCard } from 'types/transport';

interface MainProps {
  type: 'nomal' | 'transport-selectOperation' | 'selected';
  transportCard: tTransportCard;
  flgOmit: boolean;
  callbackClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

/*
export default function Main({
  type,
  transportCard,
  flgOmit = true,
  callbackClick = () => {},
}: MainProps) {
  const navigate = useNavigate();

  const movePageProject = () => {
    navigate(`/project/edit/${transportCard.pj_id}`);
  };

  if (type === "nomal") {
    return (
      <TransportCardNomal
        transportCard={transportCard}
        flgOmit={flgOmit}
        callbackClick={callbackClick}
      ></TransportCardNomal>
    );
  }

  return (
    <TransportCardToSchedule transportCard={transportCard} flgOmit={flgOmit} />
  );
}
  */

/**
 * 運行指示作成用輸送情報カード
 * @param param0
 * @returns
 */
export const Nomal = forwardRef<
  HTMLDivElement,
  {
    transportCard: tTransportCard;
    callbackClick: (event: React.MouseEvent<HTMLElement>) => void;
    flgOmit?: any;
    ref?: any;
    children?: React.ReactNode;
  }
>((props, ref) => {
  const { transportCard, callbackClick, flgOmit = true, children } = props;
  const navigate = useNavigate();

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // 親への伝播を止める
    if (flgOmit) {
      return;
    }
    navigate(`/project/edit/${transportCard.pj_id}`);
  };

  return (
    <>
      <BaseCard
        ref={ref}
        flgOmit={flgOmit}
        callbackDoubleClick={handleCardDoubleClick}
        callbackClick={callbackClick}
      >
        {children}
        <Contents transportCard={transportCard} flgOmit={flgOmit} />
      </BaseCard>
    </>
  );
});
Nomal.displayName = 'transportcard-card-nomal';

export const LineNomal = forwardRef<
  HTMLDivElement,
  {
    transportCard: tTransportCard;
    callbackClick: (event: React.MouseEvent<HTMLElement>) => void;
    callbackDoubleClick?: (event: React.MouseEvent<HTMLElement>) => void;
    flgOmit?: any;
    ref?: any;
    children?: React.ReactNode;
    type?: cardType;
  }
>((props, ref) => {
  const {
    transportCard,
    callbackClick,
    callbackDoubleClick,
    flgOmit = true,
    children,
    type = 'box',
  } = props;

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // 親への伝播を止める
    if (flgOmit) {
      return;
    }
    if (callbackDoubleClick) {
      callbackDoubleClick(event);
    }
  };

  return (
    <>
      <BaseCard
        ref={ref}
        flgOmit={flgOmit}
        callbackDoubleClick={handleCardDoubleClick}
        callbackClick={callbackClick}
        type={type}
      >
        {children}
        <Contents transportCard={transportCard} flgOmit={flgOmit} />
      </BaseCard>
    </>
  );
});
LineNomal.displayName = 'transportcard-card-line-nomal';

const Contents = ({
  transportCard,
  flgOmit,
}: {
  transportCard: tTransportCard;
  flgOmit?: boolean;
}) => {
  const { tranMethods } = useContext(MstDataContext);
  const sameDate = isSameDate(
    transportCard.load_datetime,
    transportCard.unload_datetime
  );

  const vehicleToTrailer = getTranVehicleToTrailer({
    instructions: transportCard.instructions || [],
  });

  // 積日と卸日の差が2日以上かを判定
  const diff = diffDayCount(
    transportCard.load_datetime,
    transportCard.unload_datetime
  );

  const Labels = (): React.ReactElement => {
    if (flgOmit) {
      return <></>;
    }
    return (
      <>
        <Typography>
          {`${truncateString(transportCard.company_abbreviation, 5)}`}
        </Typography>

        <Box className="contents">
          <Typography className="load">
            {truncateString(transportCard.load_name, 6)}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Typography className="unload">
              {truncateString(transportCard.unload_name, 6)}
            </Typography>
            {diff < -1 && (
              <Typography className="attribute-chip unload-day">{`${formatMonthDay(new Date(transportCard.unload_datetime))}`}</Typography>
            )}
          </Box>
        </Box>
      </>
    );
  };

  const AttributeChip = (): React.ReactElement => {
    if (flgOmit) {
      return <></>;
    }
    return (
      <>
        {vehicleToTrailer && (
          <Typography className="chip vehicle">{vehicleToTrailer}</Typography>
        )}
        {tranMethods?.map((tm) => {
          const obj = checkTranMethod(
            transportCard.instructions || [],
            [],
            tm.id
          );

          if (obj) {
            return (
              <Typography
                key={`TransportCard-${transportCard.pj_id}-tm-${tm.id}`}
                className={`chip transport-method transport-method-${tm.id}`}
              >
                {tm.name_chip}
              </Typography>
            );
          }
        })}
        {sameDate && <Typography className="chip evening">当</Typography>}
        {transportCard.waypoint_count > 2 && (
          <Typography className="chip waypoints">{`+${
            transportCard.waypoint_count - 2
          }`}</Typography>
        )}
        {transportCard.warning_level > 0 && (
          <Typography
            className={`chip warning-level wl-${transportCard.warning_level}`}
          >{`W${transportCard.warning_level}`}</Typography>
        )}
      </>
    );
  };

  const KeyChip = (): React.ReactElement => {
    return (
      <>
        <Typography className="chip tran-key">{`${transportCard.id}`}</Typography>
      </>
    );
  };

  return (
    <CardContents
      Labels={<Labels />}
      AttributeChip={<AttributeChip />}
      KeyChip={<KeyChip />}
    />
  );
};

/**
 * 輸送情報カード(空)
 * @param param0
 * @returns
 */
export const Blank = forwardRef<
  HTMLDivElement,
  {
    label: string;
    callbackDoubleClick?: (event: React.MouseEvent<HTMLElement>) => void;
    callbackClick?: (event: React.MouseEvent<HTMLElement>) => void;
    flgOmit?: any;
    ref?: any;
    isOver: boolean;
  }
>((props, ref) => {
  const {
    label,
    callbackDoubleClick,
    callbackClick,
    flgOmit = true,
    isOver = false,
  } = props;

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    if (callbackDoubleClick) {
      callbackDoubleClick(event);
    }
  };

  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    if (callbackClick) {
      callbackClick(event);
    }
  };

  return (
    <Box className={`TransportCardBlank`}>
      <BaseCard
        ref={ref}
        flgOmit={flgOmit}
        callbackDoubleClick={handleCardDoubleClick}
        callbackClick={handleCardClick}
        className={'blank'}
        isOver={isOver}
      >
        {!flgOmit && <Typography className="stamp">{`${label}`}</Typography>}
      </BaseCard>
    </Box>
  );
});
Blank.displayName = 'transportcard-card-blank';

/**
 * 運行指示作成用輸送情報カード
 * @param param0
 * @returns
 */
export const TransportCardToDragDrop = ({
  ddType,
  transportCard, // 輸送情報
  callbackDoubleClick = undefined, // ダブルクリック時の処理
  flgOmit = true, // 略称表示かどうか
  switchCtrl = true, // 選択機能の有無
  flgAttributeLabel = true, // 属性ラベルの表示
}: {
  ddType: string;
  transportCard: tTransportCard;
  callbackDoubleClick?: (() => void) | undefined;
  flgOmit?: any;
  switchCtrl?: boolean;
  flgAttributeLabel?: boolean;
}) => {
  const { transportCards: transports } = useSelection();
  const { addSelection, removeSelection } = useSelection(); // グローバル状態の関数

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // ポップオーバーのアンカー
  const [selected, setSelected] = useState(false);

  /**
   * D&D用のフック
   */
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ddType,
    item: transportCard,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  /**
   * クリック時の処理
   * @param event
   */
  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    // event.currentTargetを変数に保存する
    const targetElement = event.currentTarget;

    setAnchorEl(targetElement); // クリックした要素をアンカーに設定
  };

  const handleToolchipClose = () => {
    setAnchorEl(null); // ポップオーバーを閉じる
  };

  /**
   * 解除用ツールチップ処理
   * @param type
   */
  const handleToolchipSelectOFF = () => {
    removeSelection({ transportCard });
    handleToolchipClose(); // 選択後にメニューを閉じる
  };

  const checkSelectedTransports = () => {
    return checkInTransportCard(transports, transportCard);
  };
  useEffect(() => {
    setSelected(checkSelectedTransports());
  }, [transports]);

  //useEffect(() => {}, [anchorEl]);

  const open = Boolean(anchorEl);

  return (
    <>
      <Nomal
        ref={drag}
        transportCard={transportCard}
        flgOmit={flgOmit}
        callbackClick={handleCardClick}
      />
      <Toolchip
        open={open}
        anchorEl={anchorEl}
        handleClose={handleToolchipClose}
      >
        {selected && (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleToolchipSelectOFF()}
              sx={{ marginRight: 1, marginTop: 1 }}
            >
              解除
            </Button>
          </>
        )}
      </Toolchip>
    </>
  );
};

/**
 * D&Dができる運送情報カード
 * @param param0
 * @returns
 */
export const DragTransportCardArea = ({
  ddType,
  transportCards,
  optionNode,
}: {
  ddType: string;
  transportCards: tTransportCard[];
  optionNode: React.ReactNode;
}) => {
  return (
    <Container>
      {transportCards.length !== 0 && (
        <Grid container>
          <Grid item xs={11}>
            <Box sx={{ display: 'flex', flexFlow: 'wrap' }}>
              {transportCards.map((transportCard, index) => {
                return (
                  <TransportCardToDragDrop
                    ddType={ddType}
                    key={`TransportCard-DragTransportCardArea-${index}`}
                    transportCard={transportCard}
                    flgOmit={false}
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={1}>
            {optionNode}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};
