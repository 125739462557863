import { Grid } from '@mui/material';
import { PrimaryButton } from 'atoms/Button';
import Modal from 'atoms/Modal';
import * as ClosingComponent from 'components/closing/Index';
import React from 'react';
import { tInvoice } from 'types/invoice';

interface MainProps {
  invoice: tInvoice;
  setInvoice: React.Dispatch<React.SetStateAction<tInvoice>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callbackSuccess: () => void;
}
export default function Main({
  invoice,
  setInvoice,
  open,
  setOpen,
  callbackSuccess,
}: MainProps) {
  const handleClick = () => {
    callbackSuccess();
  };

  return (
    <Modal
      title="請求書作成"
      actions={
        <>
          <PrimaryButton label={'発行'} onClick={handleClick} />
        </>
      }
      open={open}
      onClose={() => setOpen(false)}
      width="xl"
    >
      <Grid container spacing={2}>
        <ClosingComponent.CompanyPostalCode
          values={invoice}
          setValues={setInvoice}
        />
        <ClosingComponent.CompanyAddress1
          values={invoice}
          setValues={setInvoice}
        />
        <ClosingComponent.CompanyAddress2
          values={invoice}
          setValues={setInvoice}
        />
        <ClosingComponent.ProjectInChargeName
          values={invoice}
          setValues={setInvoice}
        />
      </Grid>
    </Modal>
  );
}
