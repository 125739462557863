import { tUser, tUserSearch } from 'types/mst';
export const initUser: tUser = {
  id: 0,
  email: '',
  password: '',
  created_at: new Date(),
  updated_at: new Date(),
  deleted_at: null,
  family_name: '',
  given_name: '',
  family_name_kana: '',
  given_name_kana: '',
  birthday: new Date().toISOString().split('T')[0],
  tell: '',
  post_number: '',
  address: '',
  role_id: 0,
  v_id: null,
  hire_date: new Date().toISOString().split('T')[0],
  full_name: '',
  timezone: 0,
};

export const initSearchUser: tUserSearch = {
  freewords: '',
};
