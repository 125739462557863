import { ItemTextFiled } from 'components/Common';
import React from 'react';
import { ItemFieldProps } from 'types/index';
import { tTransport } from 'types/transport';

interface GridItemFieldProps {
  values: tTransport;
  setValues: React.Dispatch<React.SetStateAction<tTransport>>;
  size?: Record<string, number>;
}

/**
 * コンテナ番号
 */
export const ContainerCode = React.memo(
  ({ value, setValues, size }: ItemFieldProps) => {
    if (!size) {
      size = { xs: 12, sm: 8, md: 9, lg: 4, xl: 4 };
    }

    return (
      <ItemTextFiled
        label="コンテナ番号"
        name="container_code"
        value={value}
        setValues={setValues}
        size={size}
      />
    );
  }
);
