import { tRole, tRoleSearch } from 'types/mst';

export const initialRole: tRole = {
  id: 0,
  name: '',
  admin: false,
  project: false,
  operation: false,
  paperwork: false,
  mst: false,
  driver: false,
};

export const initSearchRole: tRoleSearch = {
  freewords: '',
};
