import Axios from 'axios';
import * as env from 'functions/env';
export const API_URL = {
  base: env.API_ROOT_URL(),
  sanctum: env.API_URL_BASE() + '/sanctum/csrf-cookie',
  login: env.API_ROOT_URL() + '/login',
  logout: env.API_ROOT_URL() + '/logout',
  checkToken: env.API_ROOT_URL() + '/token-check',
  register: env.API_ROOT_URL() + '/register',
  ProjectKind: env.API_ROOT_URL() + '/project-kind/index',
};

/**
 * CSRFトークンがない場合に取得する同期関数
 */
export async function getCsrf() {
  const instance = Axios.create({
    baseURL: API_URL.base,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
    withCredentials: true,
    timeout: 60000,
    maxRedirects: 5,
  });

  await instance.get(API_URL.sanctum, { withCredentials: true });
}

export function create() {
  const instance = Axios.create({
    baseURL: API_URL.base,
    transformRequest: [
      (data, headers) => {
        // JSON.stringifyを適用
        return JSON.stringify(data);
      },
    ],
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json', // JSON形式
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Accept-Language': 'ja', // ここに追加
      /*
      "X-CSRF-TOKEN": csrfToken,
      Referer: document.location.href,
      */
    },
    withCredentials: true,
    timeout: 60000,
    maxRedirects: 5,

    // カスタム paramsSerializer を指定
    /*
    paramsSerializer: (params) => {
      return qs.stringify(params, {
        skipNulls: false, // null値をスキップしない
        strictNullHandling: true, // nullを明示的に "key=null" として出力
      });
    },
    */
  });

  return instance;
}

export async function login(auth: any, callbacker: any | null) {
  // await getCsrf(); // CSRFトークンを取得
  const instance = await create();
  instance
    .post(API_URL.login, { auth })
    .then((res) => {
      // 成功でtokenをローカルストレージに保存
      localStorage.setItem('token', res.data.token);
      callbacker(res, true);
    })
    .catch((error) => {
      console.error('Error during login:', error);
      callbacker(null, false);
    });
}

export async function logout(callbacker: any | null = null) {
  // await getCsrf(); // CSRFトークンを取得
  const ins = await create();
  return ins
    .post(API_URL.logout, {})
    .then((res) => {
      // 成功でlocalStorageをクリア
      localStorage.clear();
      if (callbacker) {
        callbacker(res);
      }
    })
    .catch((error) => {
      console.error('Error during logout:', error);
      if (callbacker) {
        callbacker(error.response);
      }
    });
}
