import Loading from 'atoms/Loading';
import { AutocompleteSelect } from 'atoms/Select';
import { GridItemTextFieldCustom } from 'components/GridItem';
import { MstDataContext } from 'contexts/Mst';
import { handleChangeValues } from 'functions/handles';
import React, { useContext } from 'react';
import { ItemSelectProps, List, ListItem } from 'types';

export const ClientCompany = ({
  label = '取引先',
  name = 'c_id',
  value,
  setValues,
  size = { xs: 12, lg: 3, xl: 3 },
}: ItemSelectProps) => {
  const { loading, companies } = useContext(MstDataContext);
  if (loading) {
    return <Loading flg={true} />;
  }

  const options = companies?.map((company) => ({
    id: company.id,
    label: company.abbreviation,
  })) as List;

  const selectedOption = options?.find((option) => option.id === value);

  return (
    <GridItemTextFieldCustom size={size}>
      <AutocompleteSelect
        disableClearable={false}
        name={name}
        label={label}
        options={options}
        value={selectedOption || null}
        onChange={(
          e: React.SyntheticEvent<Element, Event>,
          newValue: ListItem
        ) => {
          if (!newValue) {
            handleChangeValues(null, name, setValues);
            return;
          }
          handleChangeValues(newValue.id, name, setValues);
        }}
      />
    </GridItemTextFieldCustom>
  );
};
