import { TableCellTextField } from 'atoms/TextField';
import { ItemNumberField, ItemTextFiled } from 'components/Common';
import React from 'react';

interface GridItemFieldProps {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  size?: Record<string, number>;
}

export const Title = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="タイトル"
    name="title"
    value={values.title}
    setValues={setValues}
  />
);

export const TotalAmount = ({ values, setValues }: GridItemFieldProps) => (
  <ItemNumberField
    label="合計金額"
    name="total_amount"
    value={values.total_amount}
    setValues={setValues}
  />
);

export const Tax = ({ values, setValues }: GridItemFieldProps) => (
  <ItemNumberField
    label="消費税額"
    name="tax"
    value={values.tax}
    setValues={setValues}
  />
);

export const BillingAmount = ({ values, setValues }: GridItemFieldProps) => (
  <ItemNumberField
    label="請求金額"
    name="billing_amount"
    value={values.billing_amount}
    setValues={setValues}
  />
);

export const DetailCount = ({ values, setValues }: GridItemFieldProps) => (
  <ItemNumberField
    label="案件件数"
    name="detail_count"
    value={values.detail_count}
    setValues={setValues}
  />
);

export const CompanyInvoiceNo = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="インボイス番号"
    name="c_invoice_no"
    value={values.c_invoice_no}
    setValues={setValues}
  />
);

export const CompanyName = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="会社名"
    name="c_name"
    value={values.c_name}
    setValues={setValues}
  />
);

export const CompanyPostalCode = ({
  values,
  setValues,
}: GridItemFieldProps) => (
  <ItemTextFiled
    label="郵便番号"
    name="c_postal_code"
    value={values.c_postal_code}
    setValues={setValues}
  />
);

export const CompanyAddress1 = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="住所1"
    name="c_address1"
    value={values.c_address1}
    setValues={setValues}
    size={{ xs: 12, sm: 6, lg: 4 }}
  />
);

export const CompanyAddress2 = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="住所2"
    name="c_address2"
    value={values.c_address2}
    setValues={setValues}
    size={{ xs: 12, sm: 6, lg: 4 }}
  />
);

export const ProjectInChargeName = ({
  values,
  setValues,
}: GridItemFieldProps) => (
  <ItemTextFiled
    label="担当者名"
    name="pj_in_charge_name"
    value={values.pj_in_charge_name}
    setValues={setValues}
  />
);

export const ProjectMemo = ({
  values,
  setValues,
  pj_id,
}: GridItemFieldProps & { pj_id: number }) => (
  <TableCellTextField
    props={{
      name: `memo`,
      multiline: true,
      rows: 2,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prev: any) => ({
          ...prev,
          [pj_id]: e.target.value,
        }));
      },
    }}
  />
);
