import { Grid } from '@mui/material';
import { FlexColumnBox } from 'atoms/Box';
import * as cSelfInfomation from 'components/mst/SelfInfomation';
import * as BtnSelfInfomation from 'components/selfInformation/Button';
import { initSelfInformation } from 'const/selfInfomation';
import ContentsFrame from 'frames/ContentsFrame';
import { getSelfInfomation } from 'functions/api/mst';
import { useEffect, useState } from 'react';
import { tSelfInformation } from 'types/mst';

export default function Main() {
  const [values, setValues] = useState<tSelfInformation>(initSelfInformation);

  useEffect(() => {
    getSelfInfomation()
      .then((res) => {
        setValues(res.data);
      })
      .catch((err) => {
        alert('自社情報の取得に失敗しました。');
      });
  }, []);

  return (
    <ContentsFrame
      HeadContent={<HeadContent values={values} />}
      MainContent={<Details values={values} setValues={setValues} />}
      SubContent={<></>}
    />
  );
}

interface HeadContentProps {
  values: tSelfInformation;
}
const HeadContent = ({ values }: HeadContentProps) => {
  return (
    <>
      <BtnSelfInfomation.Store data={values} />
    </>
  );
};

interface DetailsProps {
  values: tSelfInformation;
  setValues: React.Dispatch<React.SetStateAction<tSelfInformation>>;
}
const Details = ({ values, setValues }: DetailsProps) => {
  return (
    <FlexColumnBox>
      <Grid container spacing={2}>
        <cSelfInfomation.Name values={values} setValues={setValues} />
        <cSelfInfomation.CorporateNumber
          values={values}
          setValues={setValues}
        />
      </Grid>
      <Grid container spacing={2}>
        <cSelfInfomation.Tel values={values} setValues={setValues} />
        <cSelfInfomation.Fax values={values} setValues={setValues} />
      </Grid>
      <Grid container spacing={2}>
        <cSelfInfomation.PostNumber values={values} setValues={setValues} />
        <cSelfInfomation.Address1 values={values} setValues={setValues} />
        <cSelfInfomation.Address2 values={values} setValues={setValues} />
      </Grid>
      <Grid container spacing={2}>
        <cSelfInfomation.BillingInfo values={values} setValues={setValues} />
        <cSelfInfomation.Notes values={values} setValues={setValues} />
      </Grid>
    </FlexColumnBox>
  );
};
