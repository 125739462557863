import {
  getInstructionCardList,
  getInstructionCards,
} from 'functions/api/instruction';
import { apiGetIndexSuccess, apiGetListSuccess, tOrder } from 'types/index';
import {
  cbGetInstructionCardList,
  cbGetInstructionCards,
  tInstruction,
} from 'types/instruction';

export const checkTranMethod = (
  instructions: tInstruction[],
  tmpInstructions: tInstruction[],
  tmId: number
) => {
  const margeInst = instructions.concat(tmpInstructions);

  return margeInst.find((inst) => inst.tm_id === tmId);
};

/**
 * 運行指示検索処理（ページネーション付き）
 * @param page
 * @param filter
 * @param callback
 */
export const searchIndex = (
  page: number,
  filter: any = {},
  orders: tOrder[] = [],
  withs: string[] = [],
  callback: (props: cbGetInstructionCards) => void
) => {
  getInstructionCards({
    page: page,
    filter: filter,
    order: orders,
    withs: withs,
    callbackSuccess: (props: apiGetIndexSuccess) => callback(props),
  });
};

/**
 * 運行指示検索処理（一覧）
 * @param page
 * @param filter
 * @param callback
 */
export const searchList = (
  filter: any = {},
  order: tOrder[] = [],
  withs: any[] = [],
  callback: (props: cbGetInstructionCardList) => void
) => {
  getInstructionCardList({
    filter: filter,
    order: order,
    withs: withs,
    callbackSuccess: (props: apiGetListSuccess) => callback(props),
  });
};

/**
 * 最初の車番とシャーシ番号を取得
 * @param instructions
 * @param tmpInstructions
 * @returns 車番
 */
export function getTranVehicleToTrailer({
  instructions,
  tmpInstructions = [],
}: {
  instructions: tInstruction[];
  tmpInstructions?: tInstruction[];
}) {
  const margeInst = instructions.concat(tmpInstructions);

  // ドライバーの車番を取得
  const driver = margeInst.find((inst) => inst.v_id !== null);

  // 取得できなかった（自社車がない）場合は空文字を返す
  if (!driver) {
    return null;
  }

  let vehicleNumber = driver.vehicle?.plate_number || ''; // シャーシ番号がある場合はシャーシ番号を取得

  if (!driver.start_trailer_v_id) {
    // 最初の運行にシャーシがない場合は後ろを探す
    const chassi = margeInst.find(
      (inst) => inst.v_id !== null && inst.start_trailer_v_id !== null
    );

    if (chassi) {
      vehicleNumber = `${vehicleNumber}-${chassi.start_trailer?.plate_number}`; // シャーシがあった場合
    }
  } else {
    vehicleNumber = `${vehicleNumber}-${driver.start_trailer?.plate_number}`; // シャーシがあった場合
  }

  return vehicleNumber;
}

/**
 * 輸送情報最初の車両番号と車両IDを取得する
 * @param param0
 * @returns
 */
export const getFirstTrailer = ({
  instructions,
  tmpInstructions = [],
}: {
  instructions: tInstruction[];
  tmpInstructions?: tInstruction[];
}) => {
  const margeInst = instructions.concat(tmpInstructions);

  // ドライバーのIDを取得
  const driver = margeInst.find((inst) => inst.v_id !== null);

  // 自社シャーシのIDを取得
  const ownTrailer = margeInst.find((inst) => inst.start_trailer_v_id !== null);

  // 自社シャーシ（備考）を取得
  const ownTrailerMemo = margeInst.find(
    (inst) => inst.start_trailer_memo !== ''
  );

  return {
    driver_id: driver?.v_id,
    trailer_id: ownTrailer?.start_trailer_v_id || null,
    trailer_memo: ownTrailerMemo?.start_trailer_memo || '',
  };
};
