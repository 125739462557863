import {
  Box,
  Grid,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import PageNation from 'components/Pagenation';
import { MstDataContext } from 'contexts/Mst';
import TableFrame from 'frames/TableFrame';
import { getHistories } from 'functions/api/history';
import { getInstructionCardList } from 'functions/api/instruction';
import { getAddAnyDay } from 'functions/time';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tHistory } from 'types/history';
import { apiGetIndexSuccess, apiGetListSuccess, tOrder } from 'types/index';
import { tInstructionCard } from 'types/instruction';

export default function App() {
  const theme = useTheme();
  const { selfInfomation } = useContext(MstDataContext);
  return (
    <Box sx={{ px: theme.spacing(2), py: theme.spacing(1) }}>
      <Typography variant="h1">{selfInfomation?.name}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={7} xl={6}>
          <NowInstructionTable />
        </Grid>
        <Grid item xs={12} lg={5} xl={4} sx={{ maxHeight: '680px' }}>
          <HistoryTable />
        </Grid>
      </Grid>
    </Box>
  );
}

////////////////////////////////////////////////// 現輸送情報
const NowInstructionTable = () => {
  const { SYSTEM } = useContext(MstDataContext);

  const [instructions, setInstructions] = useState<tInstructionCard[]>([]);
  const [terms, setTerms] = useState({
    start_datetime_from: getAddAnyDay(-7, 0, 0),
    start_datetime_to: getAddAnyDay(7, 0, 0),
    status: [
      SYSTEM?.instruction.status.AlreadyContacted,
      SYSTEM?.instruction.status.InTransit,
    ],
    tm_id: SYSTEM?.tranMethod.own.id,
  });

  const order: tOrder[] = [{ field: 'driver_id', direction: 'asc' }];

  const callback = ({ data, filter }: apiGetListSuccess) => {
    setInstructions(data);
  };

  const getSearch = () => {
    getInstructionCardList({
      filter: terms,
      order: order,
      withs: ['projects'],
      callbackSuccess: callback,
    });
  };

  useEffect(() => {
    if (SYSTEM) {
      setTerms((prev) => ({
        ...prev,
        status: [
          SYSTEM?.instruction.status.AlreadyContacted,
          SYSTEM?.instruction.status.InTransit,
        ],
        tm_id: SYSTEM?.tranMethod.own.id,
      }));
      getSearch();
    }
  }, [SYSTEM]);

  return (
    <TableFrame
      HeadContent={<></>}
      TableHeaderRows={<NowInstructionTableHeaderRows />}
      TableBodyRows={<NowInstructionTableBodyRows data={instructions} />}
      SubContent={<></>}
    />
  );
};

const NowInstructionTableHeaderRows = () => {
  return (
    <TableRow>
      <TableCell>ドライバー</TableCell>
      <TableCell>ステータス</TableCell>
      <TableCell>開始日時</TableCell>
      <TableCell>開始地点</TableCell>
      <TableCell>目的地点</TableCell>
      <TableCell>備考</TableCell>
    </TableRow>
  );
};

const NowInstructionTableBodyRows = ({
  data,
}: {
  data: tInstructionCard[];
}) => {
  return (
    <>
      {data.map((row) => (
        <TableRow key={row.id}>
          <TableCell>
            <Typography>{row.driver_name}</Typography>
            <Typography>
              {row.v_plate}
              {row.start_trailer_plate && `-${row.start_trailer_plate}`}
            </Typography>
          </TableCell>
          <TableCell>{row.status_label}</TableCell>
          <TableCell>{row.execute_datetime}</TableCell>
          <TableCell>
            <Typography>{row.start_name}</Typography>
            <Typography>{row.start_address}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{row.end_name}</Typography>
            <Typography>{row.end_address}</Typography>
          </TableCell>
          <TableCell>
            {row.pj_memo && (
              <Typography
                className={'whiteSpace-preLine'}
              >{`案件情報\n${row.pj_memo}`}</Typography>
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

////////////////////////////////////////////////// 履歴テーブル
const HistoryTable = () => {
  const [histories, setHitories] = useState<tHistory[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getSearch = (page: number) => {
    getHistories({
      page: page,
      callbackSuccess: ({
        data,
        currentPage,
        lastPage,
        filter,
      }: apiGetIndexSuccess & { data: tHistory[] }) => {
        setHitories(data);
        setCurrentPage(currentPage);
        setLastPage(lastPage);
        setTotalPages(lastPage);
      },
    });
  };

  useEffect(() => {
    getSearch(1);
  }, []);
  return (
    <TableFrame
      HeadContent={<></>}
      TableHeaderRows={<HistoryTableHeaderRows />}
      TableBodyRows={<HistoryTableBodyRows data={histories} />}
      SubContent={
        <PageNationContent
          totalPages={totalPages}
          currentPage={currentPage}
          getSearch={getSearch}
        />
      }
    />
  );
};

const HistoryTableHeaderRows = () => {
  return (
    <TableRow>
      <TableCell>日時</TableCell>
      <TableCell>対象</TableCell>
      <TableCell>処理内容</TableCell>
      <TableCell>操作ユーザ</TableCell>
    </TableRow>
  );
};

const HistoryTableBodyRows = ({ data }: { data: tHistory[] }) => {
  const navigation = useNavigate();
  return (
    <>
      {data.map((row) => (
        <TableRow
          key={row.id}
          onDoubleClick={() => navigation(getHistoryEditLink(row))}
        >
          <TableCell>
            {new Date(row.created_datetime).toLocaleString()}
          </TableCell>
          <TableCell>{`${row.table_name_jp}(ID:${row.model_id})`}</TableCell>
          <TableCell>{row.action_jp}</TableCell>
          <TableCell>{row.user?.full_name}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

////////////////////////////////////////////////// 共通
interface PageNationContentProps {
  totalPages: number;
  currentPage: number;
  getSearch: (page: number) => void;
}

const PageNationContent = ({
  totalPages,
  currentPage,
  getSearch,
}: PageNationContentProps) => {
  return (
    <PageNation
      totalPages={totalPages}
      currentPage={currentPage}
      handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
        getSearch(value)
      }
    />
  );
};

const getHistoryEditLink = (row: tHistory) => {
  switch (row.table_name) {
    // マスタ系
    case 'roles':
      return `mst//roles/edit/${row.model_id}`;
    case 'self_information':
      return `mst/self-information`;

    case 'projects': // 案件情報
      return `/project/edit/${row.model_id}`;
    case 'instructions': // 運行指示
      return `/instructions/edit/${row.model_id}`;
    default:
      return '';
  }
};
