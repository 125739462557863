import ProtectedRoute from 'components/ProtectedRoute';
import { menus } from 'const/menu';
import { MstDataProvider } from 'contexts/Mst';
import { SelectionProvider } from 'contexts/Schedules';
import FullScreenFrame from 'frames/FullScreenFrame';
import HomeLayout from 'frames/HomeFrame';
import MainLayout from 'frames/MainFrame';
import FSSchedules from 'pages/full-screen/TransportSchedule';
import Top from 'pages/Index';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProviderCustom } from 'themes/Base';
import './index.css';
import reportWebVitals from './reportWebVitals';

const App = () => {
  return (
    <Router>
      <MstDataProvider>
        <SelectionProvider>
          <ThemeProviderCustom>
            <Routes>
              {/* 非保護ルート */}
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />

              {/* 保護されたルート */}
              <Route element={<ProtectedRoute />}>
                {/* ホームページ */}
                <Route path="/" element={<HomeLayout />}>
                  <Route path="/" element={<Top />} />
                </Route>

                {/* フルスクリーンのスケジュールページ */}
                <Route element={<FullScreenFrame />}>
                  <Route
                    path="/full-screen/schedules"
                    element={<FSSchedules />}
                  />
                </Route>

                {/* メインレイアウト */}
                {menus.map((menu) => (
                  <Route key={menu.path} element={<MainLayout menu={menu} />}>
                    <Route path={menu.path} element={menu.element} />
                    {menu.subMenus.map((subMenu) => (
                      <Route
                        key={`${menu.path}-${subMenu.path}`}
                        path={subMenu.path}
                        element={subMenu.element}
                      />
                    ))}
                  </Route>
                ))}
              </Route>
            </Routes>
          </ThemeProviderCustom>
        </SelectionProvider>
      </MstDataProvider>
    </Router>
  );
};

// トップDomを取得
const rootDom = document.getElementById('root');
if (rootDom) {
  const root = ReactDOM.createRoot(rootDom);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

reportWebVitals();
