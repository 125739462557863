import { Box } from '@mui/material';
import Loading from 'atoms/Loading';
import { MstDataContext } from 'contexts/Mst';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { FullScreenThemeProvider } from 'themes/FullScreenTheme';

//interface MainProps {}
export default function Main() {
  //const [currentPage, setCurrentPage] = useState('');
  const { loading } = useContext(MstDataContext);

  const styles = {
    main: {
      height: '100vh !important',
    },
  };
  if (loading) {
    return <Loading flg={true} />;
  }

  return (
    <FullScreenThemeProvider>
      <Box component={'main'} sx={styles.main}>
        <Outlet />
      </Box>
    </FullScreenThemeProvider>
  );
}
