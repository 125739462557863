import { cSwitch } from 'const/index';
import { getConsts } from 'functions/api/const';
import {
  //getCarModel,
  getAddressList,
  getCompanyList,
  getLuggageList,
  getProjectKindList,
  getRoleList,
  getSelfInfomation,
  getTransportMethodList,
  getUserList,
  getVehicleList,
  getVehicleTypeList,
} from 'functions/api/mst';
import log from 'functions/logger';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { tAddress } from 'types/address';
import { tCompany } from 'types/company';
import { ListItem } from 'types/index';
import {
  tLuggage,
  tProjectKind,
  //tCarModel,
  tRole,
  tSelfInformation,
  tTransportMethod,
  tUser,
  tVehicle,
  tVehicleType,
} from 'types/mst';
import { tSYSTEM } from 'types/system';

interface MstDataContextProps {
  loading: boolean;
  users: tUser[] | null;
  loginUser: tUser | any;
  projectKinds: tProjectKind[] | null;
  companies: tCompany[] | null;
  luggages: tLuggage[] | null;
  //carModels: tCarModel[] | null;
  addresses: tAddress[] | null;
  roles: tRole[] | null;
  vehicles: tVehicle[] | null;
  trailer: tVehicle[] | null;
  vehicleTypes: tVehicleType[] | null;
  drivers: ListItem[] | null;
  projectUsers: ListItem[] | null;
  selfInfomation: tSelfInformation | null;
  tranMethods: tTransportMethod[] | null;
  SYSTEM: tSYSTEM | null;
  fetchData: () => Promise<void>;
}

// デフォルト値を定義
const defaultValue: MstDataContextProps = {
  loading: false,
  users: null,
  loginUser: null,
  projectKinds: null,
  companies: null,
  luggages: null,
  //carModels: null,
  addresses: null,
  roles: null,
  vehicles: null,
  trailer: null,
  vehicleTypes: null,
  drivers: null,
  projectUsers: null,
  selfInfomation: null,
  tranMethods: null,
  SYSTEM: null,
  fetchData: async () => {
    // This function will be replaced by the actual implementation in the provider
  },
};

export const MstDataContext = createContext<MstDataContextProps>(defaultValue);

interface MstDataProviderProps {
  children: ReactNode;
}

export const MstDataProvider = ({ children }: MstDataProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [loginUser, setLoginUser] = useState(null);
  const [projectKinds, setProjectKinds] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [luggages, setLuggages] = useState(null);
  //const [carModels, setCarModels] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const [roles, setRoles] = useState(null);
  const [vehicles, setVehicles] = useState(null);
  const [trailer, setTrailer] = useState(null);
  const [vehicleTypes, setVehicleTypes] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [projectUsers, setProjectUsers] = useState(null);
  const [selfInfomation, setSelfInfomation] = useState(null);
  const [tranMethods, setTranMethods] = useState(null);
  const [SYSTEM, setConstSystem] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    // csrfトークンを取得しセット
    //await axios.getCsrf();
    try {
      // 並行してデータを取得
      const [
        usersRes,
        rolesRes,
        projectKindsRes,
        companiesRes,
        luggagesRes,
        //carModelsRes,
        vehiclesRes,
        addressesRes,
        vehicleTypesRes,
        selfInfoRes,
        tranMethodRes,
        SYSTEMRes,
      ] = await Promise.all([
        getUserList(),
        getRoleList(),
        getProjectKindList(),
        getCompanyList(),
        getLuggageList(),
        //getCarModel(),
        getVehicleList(),
        getAddressList(),
        getVehicleTypeList(),
        getSelfInfomation(),
        getTransportMethodList({ flg_on: cSwitch.on }),
        getConsts(),
      ]);

      // データをセット
      setUsers(usersRes.data);
      setRoles(rolesRes.data);
      setProjectKinds(projectKindsRes.data);
      setCompanies(companiesRes.data);
      setLuggages(luggagesRes.data);
      //setCarModels(carModelsRes.data);
      setVehicles(vehiclesRes.data);
      setAddresses(addressesRes.data);
      setVehicleTypes(vehicleTypesRes.data);
      setSelfInfomation(selfInfoRes.data);
      setTranMethods(tranMethodRes.data);

      // 車両を自走するものとそうじゃないものに分け
      const driveVehicles = vehiclesRes.data.filter(
        (obj: tVehicle) => obj.vehicle_type?.flg_trailer === false
      );
      setVehicles(driveVehicles);
      const chassiVehicles = vehiclesRes.data.filter(
        (obj: tVehicle) => obj.vehicle_type?.flg_trailer === true
      );
      setTrailer(chassiVehicles);

      // ログインユーザ
      const lu = usersRes.data.find(
        (user: tUser) => user.email === localStorage.getItem('username')
      );
      log.debug('loginUser', lu);

      setLoginUser(lu);

      //ドライバー
      const roleDriver = rolesRes.data.filter(
        (role: tRole) => role.driver === true
      );
      const roleUsers = usersRes.data.filter((user: tUser) =>
        roleDriver.map((role: tRole) => role.id).includes(user.role_id)
      );
      const driverList = roleUsers.map((user: tUser) => ({
        id: user.id,
        label: `${user.family_name} ${user.given_name}`,
      }));
      setDrivers(driverList);

      // 営業（案件入力）
      const roleProject = rolesRes.data.filter(
        (role: tRole) => role.project === true
      );
      const roleProjectUsers = usersRes.data.filter((user: tUser) =>
        roleProject.map((role: tRole) => role.id).includes(user.role_id)
      );
      const projectUserList = roleProjectUsers.map((user: tUser) => ({
        id: user.id,
        label: `${user.family_name} ${user.given_name}`,
      }));
      setProjectUsers(projectUserList);

      // 定数
      setConstSystem(SYSTEMRes.data.system);
    } catch (error) {
      console.error('Failed to fetch common data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('username')) {
      fetchData();
    }
  }, []);

  return (
    <MstDataContext.Provider
      value={{
        users,
        loading,
        loginUser,
        projectKinds,
        companies,
        luggages,
        //carModels,
        addresses,
        roles,
        vehicles,
        trailer,
        vehicleTypes,
        drivers,
        projectUsers,
        selfInfomation,
        tranMethods,
        SYSTEM,

        fetchData,
      }}
    >
      {children}
    </MstDataContext.Provider>
  );
};
