import { SelectChangeEvent } from '@mui/material/Select';
import * as SelectFeild from 'atoms/Select';
import * as cCommon from 'components/Common';
import React from 'react';
import { tOrder, tOrderOption } from 'types/index';

interface GridItemFieldProps {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
}

export const Freewords = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="フリーワード"
    name="freewords"
    value={values.freewords}
    setValues={setValues}
    size={{ xs: 12, lg: 10, xl: 8 }}
  />
);

interface OrdersProps {
  values: tOrder;
  setValues: React.Dispatch<React.SetStateAction<tOrder[]>>;
  no: number;
  options: tOrderOption[];
}

export const Orders = ({ values, setValues, no, options }: OrdersProps) => {
  const selected = options.find(
    (option) => option.order.field === values.field
  );

  return (
    <SelectFeild.GridItemSelect
      label={`並び順${no.toString()}`}
      id="order"
      labelId="order-label"
      selectedValue={selected?.id || 0}
      size={{ xs: 12, lg: 2, xl: 4 }}
      items={options}
      handleChangeValues={(
        event: SelectChangeEvent<number | string>,
        child: React.ReactNode
      ) => {
        const option = options.find(
          (option) => option.id === Number(event.target.value)
        );
        if (option) {
          setValues((prev) => {
            const newValues = [...prev];
            newValues[no] = option.order;
            return newValues;
          });
        }
      }}
    />
  );
};
