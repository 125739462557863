import { Grid } from '@mui/material';
import { SecondButton } from 'atoms/Button';
import Modal from 'atoms/Modal';
import * as ApiBtn from 'components/company/ButtonPerson';
import { GridItemTextField } from 'components/GridItem';
import React from 'react';
import { initialPerson, tPerson } from 'types/mst';

interface Props {
  flgOpen: boolean;
  cbOnClose: () => void;
  customPerson?: tPerson;
}
export const Main = ({ flgOpen, cbOnClose, customPerson }: Props) => {
  const [editPerson, setEditPerson] = React.useState<tPerson>(
    customPerson || initialPerson
  );
  return (
    <Modal
      title="人物"
      actions={<Actions person={editPerson} cbs={cbOnClose} />}
      open={flgOpen}
      onClose={cbOnClose}
    >
      <Detail data={editPerson} setData={setEditPerson} />
    </Modal>
  );
};

interface DetailProps {
  data: tPerson;
  setData: React.Dispatch<React.SetStateAction<tPerson>>;
}
const Detail = ({ data, setData }: DetailProps) => {
  return (
    <Grid container spacing={4}>
      <GridItemTextField
        name={'family_name'}
        label="姓"
        value={data.family_name}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, family_name: e.target.value })
        }
      />

      <GridItemTextField
        name={'given_name'}
        label="名"
        value={data.given_name}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, given_name: e.target.value })
        }
      />

      <Grid item xs={12}></Grid>

      <GridItemTextField
        name={'family_name_kana'}
        label="姓（カナ）"
        value={data.family_name_kana}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, family_name_kana: e.target.value })
        }
      />

      <GridItemTextField
        name={'given_name_kana'}
        label="名（カナ）"
        value={data.given_name_kana}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, given_name_kana: e.target.value })
        }
      />

      <Grid item xs={12}></Grid>

      <GridItemTextField
        name={'tell'}
        label="電話番号"
        value={data.tell}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, tell: e.target.value })
        }
        size={{ xs: 12, lg: 4, xl: 4 }}
      />
      <GridItemTextField
        name={'email'}
        label="メールアドレス"
        value={data.email}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, email: e.target.value })
        }
        size={{ xs: 12, lg: 6, xl: 6 }}
      />

      <GridItemTextField
        name={'post_number'}
        label="郵便番号"
        value={data.post_number}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, post_number: e.target.value })
        }
      />
      <GridItemTextField
        name={'address1'}
        label="住所1"
        value={data.address1}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, address1: e.target.value })
        }
      />
      <GridItemTextField
        name={'address2'}
        label="住所2（建物など)"
        value={data.address2}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, address2: e.target.value })
        }
      />
    </Grid>
  );
};

interface ActionsProps {
  person: tPerson;
  cbs?: () => void;
  cbe?: (error: any) => void;
}

const Actions = (props: ActionsProps) => {
  return (
    <>
      {!props.person.id ? (
        <ApiBtn.Store person={props.person} cbs={props.cbs} cbe={props.cbe} />
      ) : (
        <>
          <ApiBtn.Update
            person={props.person}
            cbs={props.cbs}
            cbe={props.cbe}
          />
          <ApiBtn.Delete id={props.person.id} cbs={props.cbs} cbe={props.cbe} />
        </>
      )}
      <SecondButton label="閉じる" onClick={props.cbs} />
    </>
  );
};
