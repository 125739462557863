import * as CutomBtn from 'atoms/Button';
import { deleteDailyMemo, storeDailyMemo } from 'functions/api/dailymemo';
import log from 'functions/logger';
import { tDailyMemo } from 'types/dailymemo';

interface MainProps {
  info: tDailyMemo;
  callbackNomal?: (data: tDailyMemo) => void;
  callbackError?: (data: tDailyMemo) => void;
}

export const Save = ({ info, callbackNomal, callbackError }: MainProps) => {
  const handleDailyMemoStoreClick = () => {
    // メモの保存処理
    storeDailyMemo(info)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('登録に失敗しました。');
        }
        alert('登録しました');
        if (callbackNomal) {
          callbackNomal(res.data);
        }
      })
      .catch((error: any) => {
        log.debug('dailyMemo/Button.tsx Save Error', error);
        // エラーがある場合は、ログアウトしてログインページにリダイレクト
        if (callbackError) {
          callbackError(info);
        }
      });
  };

  return (
    <>
      <CutomBtn.PrimaryButton
        label="保存"
        onClick={handleDailyMemoStoreClick}
      />
    </>
  );
};

export const Delete = ({ info, callbackNomal, callbackError }: MainProps) => {
  const handleClick = () => {
    // メモの保存処理
    deleteDailyMemo(info.id)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('削除に失敗しました。');
        }
        alert('削除しました');
        if (callbackNomal) {
          callbackNomal(info);
        }
      })
      .catch((error: any) => {
        log.debug('dailyMemo/Button.tsx Delete Error', error);
        // エラーがある場合は、ログアウトしてログインページにリダイレクト
        if (callbackError) {
          callbackError(info);
        }
      });
  };

  return (
    <>
      <CutomBtn.DeleteButton label="削除" onClick={handleClick} />
    </>
  );
};
