import { DeleteButton, PrimaryButton, SecondButton } from 'atoms/Button';
import ErrModal from 'components/error/Modal';
import PJModal from 'components/project/Modal';
import SearchModal, {
  callbackProps as SearchModalCallbackProps,
} from 'components/project/Search';
import {
  checkUpdateProject,
  deleteProject,
  storeProject,
  updateProject,
} from 'functions/api/project';
import { isEmptyObject } from 'functions/index';
import log from 'functions/logger';
import { validationCheck } from 'functions/project/validate';
import React, { useState } from 'react';
import { tErrMsg } from 'types/index';
import {
  initProject,
  tProject,
  tProjectSearch,
  tWaypoints,
} from 'types/project';

interface MainProps {
  projectInfo: tProject;
  waypoints: tWaypoints;
  callbackNomal?: (data: tProject) => void;
  callbackError?: () => void;
}

/**
 * 新規登録処理
 * @param flgReuse
 * @returns
 */
export const Insert = ({
  label,
  projectInfo,
  waypoints,
  callbackNomal = undefined,
  callbackError = undefined,
}: { label: string } & MainProps) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  // エラーチェック＆登録処理
  const handleClick = () => {
    const validationErrors = validationCheck(projectInfo, waypoints);

    if (!isEmptyObject(validationErrors)) {
      setValidationMsg(validationErrors);
      setShowModal(true); // エラーがある場合モーダル表示
      return;
    }

    // エラーがない場合、積卸地の日時を設定して登録処理
    projectInfo.load_datetime = waypoints[0].datetime;
    projectInfo.unload_datetime = waypoints[waypoints.length - 1].datetime;
    projectInfo.waypoints = waypoints;

    storeProject(projectInfo)
      .then((res) => {
        if (res.status !== 200) throw new Error('登録に失敗しました');
        alert('登録しました');
        callbackNomal && callbackNomal(res.data);
      })
      .catch((err) => {
        alert('登録に失敗しました');
        callbackError && callbackError();
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton onClick={handleClick} label={label} />
    </>
  );
};

/**
 * 更新処理
 * @returns
 */
export const Update = ({
  projectInfo,
  waypoints,
  callbackNomal = undefined,
  callbackError = undefined,
}: MainProps) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  // エラーチェック＆登録処理
  const handleClick = () => {
    let validationMsg: tErrMsg = {};
    validationMsg = validationCheck(projectInfo, waypoints);
    if (!isEmptyObject(validationMsg)) {
      setValidationMsg(validationMsg);
      setShowModal(true); // エラーがある場合モーダル表示
      return;
    }

    // チェック処理
    checkUpdateProject(projectInfo, waypoints)
      .then((res) => {
        if (res.status !== 200) throw new Error('');

        try {
          const checkData = res.data;
          log.debug(checkData);

          // メッセージがある場合のみ配列に追加
          const checkMsg: string[] = [];
          Object.keys(checkData).map((key) => {
            const obj = checkData[key];
            if (obj.status === false) {
              checkMsg.push(obj.message);
            }
          });

          // checkMsgが空でない場合にエラーメッセージをセット
          if (checkMsg.length > 0) {
            setValidationMsg({ 更新チェック: checkMsg });
            setShowModal(true); // エラーがある場合モーダル表示
            return;
          }

          projectInfo.waypoints = waypoints;

          // 更新処理
          updateProject(projectInfo)
            .then((res) => {
              if (res.status !== 200) throw new Error('更新に失敗しました');
              alert('更新しました');
              if (callbackNomal) callbackNomal(res.data);
            })
            .catch((err) => {
              alert('更新に失敗しました');
              if (callbackError) callbackError();
            });
        } catch (error) {
          console.error(error);
        }
      })
      .catch((err) => {
        alert('更新チェック処理に失敗しました');
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton onClick={handleClick} label="更新" />
    </>
  );
};

/**
 * 削除処理
 * @returns
 */
export const Delete = ({
  pjId,
  callbackNomal,
  callbackError,
}: {
  pjId: number;
  callbackNomal?: () => void;
  callbackError?: () => void;
}) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  // エラーチェック＆登録処理
  const handleClick = () => {
    if (!window.confirm('削除しますか？')) return;
    deleteProject(pjId)
      .then((res: any) => {
        if (res.status !== 200) throw new Error('データ削除に失敗しました');
        alert('データを削除しました');
        if (callbackNomal) callbackNomal();
      })
      .catch((err) => {
        console.error(err);
        alert('データの削除に失敗しました。');
        if (callbackError) callbackError();
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <DeleteButton onClick={handleClick} label="削除" />
    </>
  );
};

export const Detail = ({
  pj_id,
  initValues = initProject,
  label = '詳細',
  callbackOpen = undefined,
  callbackClose = undefined,
}: {
  pj_id: number;
  initValues?: tProject;
  label?: string;
  callbackOpen?: () => void;
  callbackClose?: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const handleSuccess = () => {
    handleModalClose();
  };

  const handleModalOpen = () => {
    setOpen(true);
    if (callbackOpen) {
      callbackOpen();
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    if (callbackClose) {
      callbackClose();
    }
  };

  return (
    <>
      <PJModal
        open={open}
        onClose={handleModalClose}
        pjId={pj_id}
        initCustom={initValues}
        callbackNomal={() => handleSuccess}
      />
      <SecondButton label={label} onClick={handleModalOpen} />
    </>
  );
};

interface ShowSearchModalProps {
  values: tProjectSearch;
  setValues: React.Dispatch<React.SetStateAction<tProjectSearch>>;
  callbackSuccess?: (props: SearchModalCallbackProps) => void;
}
export const ShowSearchModal = ({
  values,
  setValues,
  callbackSuccess = undefined,
}: ShowSearchModalProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <SearchModal
        terms={values}
        setTerms={setValues}
        open={open}
        setOpen={setOpen}
        callback={(props: SearchModalCallbackProps) => {
          if (callbackSuccess) {
            callbackSuccess(props);
          }
        }}
      />
      <PrimaryButton label="検索" onClick={() => setOpen(true)} />
    </>
  );
};
