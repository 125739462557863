import LoadingCircular from 'atoms/Loading';
import { AutocompleteTextField } from 'atoms/TextField';
import * as cCommon from 'components/Common';
import { GridItemTextFieldCustom } from 'components/GridItem';
import { initAddress } from 'const/address';
import { NoSelect, prefectures } from 'const/index';
import { MstDataContext } from 'contexts/Mst';
import log from 'functions/logger';
import { getTargetAddress } from 'functions/mst/address';
import React, { useContext, useEffect, useState } from 'react';
import { tAddress } from 'types/address';
import { List, ListItem } from 'types/index';

interface GridItemFieldProps {
  values: tAddress;
  setValues: React.Dispatch<React.SetStateAction<tAddress>>;
  flg_office?: boolean;
  flg_load?: boolean;
  flg_unload?: boolean;
  size?: Record<string, number> | null;
  freeSolo?: boolean;
}

/**
 * AutocompleteTextFieldを使った入力フォーム
 * @param param0
 * @returns
 */
export const MstAddress = ({
  values,
  setValues,
  flg_load,
  flg_unload,
  flg_office,
  freeSolo = true,
  size,
}: GridItemFieldProps) => {
  const { addresses, loading } = useContext(MstDataContext);
  const [options, setOptions] = React.useState<ListItem[]>([]);

  useEffect(() => {
    if (addresses) {
      const ad = getTargetAddress(addresses, flg_office, flg_load, flg_unload);

      if (ad) {
        setOptions([
          ...ad.map((address: tAddress) => {
            return {
              id: address.id,
              label: address.name,
              key: address.id, // ここでkeyを追加
            };
          }),
        ]);
      } else {
        setOptions([]);
      }
    }
  }, [addresses, flg_office, flg_load, flg_unload]);

  if (loading || !addresses) {
    return <LoadingCircular flg={true} />;
  }

  return (
    <GridItemTextFieldCustom size={size}>
      <AutocompleteTextField
        name={'id'}
        freeSolo={freeSolo}
        label={'地点名'}
        options={options}
        value={values.id === 0 ? null : { id: values.id, label: values.name }}
        inputValue={values.name}
        onChange={(
          e: React.SyntheticEvent<Element, Event>,
          newValue: ListItem
        ) => {
          log.debug('newValue', newValue);
          // クリアボタン押下した時用
          if (newValue === null) {
            log.debug('if (newValue === null) {');
            setValues(initAddress);
            return;
          }

          if (typeof newValue === 'string') {
            newValue = { id: 0, label: newValue };
          }

          const selectedOption = addresses.find((address: tAddress) =>
            address.name === newValue.label ? newValue.label : ''
          );

          // 選択が変わったら
          if (selectedOption) {
            if (values.id !== selectedOption.id) {
              setValues((prev) => {
                return {
                  ...prev,
                  id: selectedOption.id,
                  name: selectedOption.name,
                };
              });
            }
          } else {
            setValues((prev) => {
              return {
                ...prev,
                id: newValue.id,
                name: newValue.label,
              };
            });
          }
        }}
      />
    </GridItemTextFieldCustom>
  );
};

/**
 * ItemSelectを使ったリスト選択フォーム
 * @param param0
 * @returns
 */
export const AddressList = ({
  values,
  setValues,
  flg_office = false,
  flg_load = false,
  flg_unload = false,
  size = null,
}: {
  values: tAddress;
  setValues: React.Dispatch<React.SetStateAction<tAddress>>;
  flg_office?: boolean;
  flg_load?: boolean;
  flg_unload?: boolean;
  size?: Record<string, number> | null;
}) => {
  const { loading, addresses } = useContext(MstDataContext);
  const [options, setOptions] = React.useState<ListItem[]>([]);

  useEffect(() => {
    if (values.id !== 0) {
      const temp = addresses?.find(
        (address: tAddress) => address.id === values.id
      );
      if (temp) {
        setValues(temp);
      } else {
        setValues(initAddress);
      }
    } else {
      setValues(initAddress);
    }
  }, [values.id]);

  useEffect(() => {
    if (addresses) {
      const ad = getTargetAddress(addresses, flg_office, flg_load, flg_unload);

      if (ad) {
        setOptions([
          { id: 0, label: '(未登録)' },
          ...ad.map((address: tAddress) => {
            return {
              id: address.id,
              label: address.name,
            };
          }),
        ]);
      } else {
        setOptions([{ id: 0, label: '' }]);
      }
    }
  }, [addresses, flg_office, flg_load, flg_unload]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <cCommon.ItemSelect
      name="id"
      value={values.id}
      labelId="id-label"
      id="id"
      label="住所名"
      items={options}
      setValues={setValues}
      size={size}
    />
  );
};

export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="名称"
    value={values.name}
    name="name"
    setValues={setValues}
  />
);

export const Prefectures = ({
  values,
  setValues,
  size,
}: GridItemFieldProps) => {
  // 初期値として values.prefectures を使用
  const [selected, setSelected] = useState<ListItem>(NoSelect);

  const [options] = useState<List>([...prefectures]);

  // 初期化: values.prefectures を元に selected を設定する
  useEffect(() => {
    const tmp = options.find(
      (option: ListItem) => option.label === values.prefectures
    );
    if (tmp && tmp.id !== selected.id) {
      setSelected(tmp);
    } else {
      setSelected(NoSelect);
    }
  }, [values.prefectures, options]);

  const tmp = options.find(
    (option: ListItem) => option.label === values.prefectures
  );

  return (
    <cCommon.ItemAutoComplete
      label="都道府県"
      name="prefectures"
      setValues={setValues}
      options={options}
      selectedOption={tmp}
      labelId="prefectures-label"
      id="prefectures"
      size={size}
      flgReturnId={false} // labelを返すように設定
    />
  );
};

export const City = ({ values, setValues, size }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="市区町村"
    value={values.city}
    name="city"
    setValues={setValues}
    size={size}
  />
);

export const Street = ({ values, setValues, size }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="番地"
    value={values.street}
    name="street"
    setValues={setValues}
    size={size}
  />
);

export const Building = ({ values, setValues, size }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="建屋・その他"
    value={values.building}
    name="building"
    setValues={setValues}
    size={size}
  />
);

/**
 * 他で住所マスタを使うとき用のコンポーネント
 * @param param0
 * @returns
 */
export const InputFormes = (props: GridItemFieldProps) => {
  const { values, setValues } = props;

  const { loading, addresses } = useContext(MstDataContext);

  /**
   * id変更処理
   */
  useEffect(() => {
    const obj = addresses?.find((adr) => adr.id === values.id);
    if (obj) {
      setValues((prev) => {
        return {
          ...prev,
          id: obj.id,
          //name: obj.name,
          post_number: obj.post_number,
          prefectures: obj.prefectures,
          city: obj.city,
          street: obj.street,
          building: obj.building,
        };
      });
    } else {
      /*
      setValues((prev) => {
        return {
          ...initddress,
          id: values.id,
          name: values.name,
        };
      });
      */
    }
  }, [values.id, addresses]);

  useEffect(() => {
    log.debug('useEffect ', values);
    const obj = addresses?.find(
      (adr) =>
        adr.name === values.name &&
        adr.post_number === values.post_number &&
        adr.prefectures === values.prefectures &&
        adr.city === values.city &&
        adr.street === values.street &&
        adr.building === values.building &&
        adr.tell === values.tell
    );

    log.debug('obj', obj);

    let updVal: any = {};

    if (!obj) {
      updVal.id = 0;
      updVal.name = values.name;
      updVal.post_number = values.post_number;
      updVal.prefectures = values.prefectures;
      updVal.city = values.city;
      updVal.street = values.street;
      updVal.building = values.building;
      updVal.tell = values.tell;
    } else if (obj.id !== values.id) {
      // 入力した結果、マスタと一致
      updVal = obj;
    }

    setValues((prev) => {
      return {
        ...prev,
        ...updVal,
      };
    });
  }, [
    values.prefectures,
    values.city,
    values.street,
    values.building,
    values.post_number,
    values.tell,
  ]);

  return (
    <>
      <MstAddress
        {...props}
        flg_office={true}
        flg_load={true}
        flg_unload={true}
        size={{ xs: 12, sm: 12, md: 4, lg: 3, xl: 2 }}
      />
      <Prefectures {...props} size={{ xs: 12, sm: 12, md: 3, lg: 1, xl: 1 }} />
      <City {...props} size={{ xs: 12, sm: 12, md: 4, lg: 2, xl: 2 }} />
      <Street {...props} size={{ xs: 12, sm: 12, md: 3, lg: 2, xl: 2 }} />
      <Building {...props} size={{ xs: 12, sm: 12, md: 4, lg: 3, xl: 3 }} />
    </>
  );
};
