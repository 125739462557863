import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/system';
import { SecondButton } from 'atoms/Button';
import Modal from 'atoms/Modal';
import * as InstButton from 'components/instruction/Button';
import * as PJButton from 'components/project/Button';
import PJDetail from 'components/project/Detail';
import { getProject } from 'functions/api/project';
import log from 'functions/logger';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeProviderCustom } from 'themes/Base';
import {
  initProject,
  initWaypoints,
  tProject,
  tWaypoints,
} from 'types/project';

interface MainProps {
  open: boolean;
  onClose: () => void;
  pjId: number;
  initCustom?: tProject;
  callbackNomal: () => void;
  callbackError?: (() => void) | undefined;
}

export default function Main({
  open,
  onClose,
  pjId,
  initCustom = initProject,
  callbackNomal,
  callbackError = undefined,
}: MainProps) {
  const theme = useTheme();
  const [project, setProject] = useState<tProject>(initCustom);
  const [waypoints, setWaypoints] = useState<tWaypoints>(initWaypoints);

  const cbsGetProject = (data: tProject) => {
    setProject(data);
    setWaypoints(data.waypoints);
  };

  const cbeGetproject = (error: any) => {
    log.debug('project/Modal.tsx getProject Error', error);
    alert('案件情報の取得に失敗しました。');
  };

  useEffect(() => {
    if (pjId) {
      getProject(pjId, cbsGetProject, cbeGetproject);
    } else {
      setProject(initCustom);
      setWaypoints(initCustom.waypoints);
    }
  }, [pjId, initCustom]);

  const callbackCloseClick = () => {
    onClose();
    callbackNomal();
  };

  const ActionBox = styled(Box)({
    display: 'flex',
    gap: theme.spacing(2),
  });

  return (
    <ThemeProviderCustom>
      <Modal
        title={`案件情報（${pjId > 0 ? pjId : '新規'}）`}
        open={open}
        onClose={onClose}
        actions={
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <ActionBox sx={{ justifyContent: 'flex-start' }}>
                {pjId > 0 && (
                  <PJButton.Delete
                    pjId={project.id}
                    callbackNomal={callbackCloseClick}
                    callbackError={callbackError}
                  />
                )}
              </ActionBox>
            </Grid>
            <Grid item xs={4}>
              <ActionBox sx={{ justifyContent: 'center' }}>
                {pjId ? (
                  <>
                    <PJButton.Update
                      projectInfo={project}
                      waypoints={waypoints}
                      callbackNomal={callbackNomal}
                      callbackError={callbackError}
                    />
                    <InstButton.ModalInstruction pjId={pjId} />
                  </>
                ) : (
                  <>
                    <PJButton.Insert
                      label={'登録'}
                      projectInfo={project}
                      waypoints={waypoints}
                      callbackNomal={callbackNomal}
                      callbackError={callbackError}
                    />
                    <PJButton.Insert
                      label={'続けて登録'}
                      projectInfo={project}
                      waypoints={waypoints}
                      callbackError={callbackError}
                    />
                  </>
                )}
              </ActionBox>
            </Grid>
            <Grid item xs={4}>
              <ActionBox sx={{ justifyContent: 'flex-end' }}>
                <SecondButton onClick={onClose} label={'閉じる'} />
              </ActionBox>
            </Grid>
          </Grid>
        }
      >
        <PJDetail
          values={project}
          setValues={setProject}
          waypoints={waypoints}
          setWaypoints={setWaypoints}
        />
      </Modal>
    </ThemeProviderCustom>
  );
}
