import { Grid, Typography } from '@mui/material';
import { FlexBox } from 'atoms/Box';
import { DateInput } from 'atoms/DateInput';
import Loading from 'atoms/Loading';
import { AutocompleteSelect } from 'atoms/Select';
import { SmallTextField } from 'atoms/TextField';
import { ItemNumberField, ItemSelect, ItemTextFiled } from 'components/Common';
import {
  GridItemNumberField,
  GridItemTextFieldCustom,
} from 'components/GridItem';
import {
  projectEditStatus,
  projectStatus,
  projectStatusInstrunctionCreated,
} from 'const/project/index';
import { MstDataContext } from 'contexts/Mst';
import { getCompanyPersonList } from 'functions/api/mst';
import { handleChangeValues } from 'functions/handles';
//import log from 'functions/logger';
import React, { useContext, useEffect, useState } from 'react';
import { List, ListItem } from 'types/index';
import { tRole } from 'types/mst';
import { tProject } from 'types/project';

interface GridItemFieldProps {
  values: tProject;
  setValues: React.Dispatch<React.SetStateAction<tProject>>;
  size?: Record<string, number>;
  flgLabel?: boolean;
}

export const LuggageID = ({ values, setValues }: GridItemFieldProps) => {
  const { loading, luggages } = useContext(MstDataContext);
  if (loading) {
    return <div>Loading...</div>;
  }

  const options = luggages?.map((luggage) => ({
    id: luggage.id,
    label: luggage.name + '(' + luggage.volume + luggage.unit + ')',
  })) as List;

  const selectedOption = options?.find((option) => option.id === values.l_id);

  return (
    <GridItemTextFieldCustom>
      <AutocompleteSelect
        name="l_id"
        label={'荷物種類'}
        options={options}
        value={selectedOption || null}
        onChange={(
          e: React.SyntheticEvent<Element, Event>,
          newValue: ListItem
        ) => {
          handleChangeValues(newValue.id, 'l_id', setValues);
        }}
      />
    </GridItemTextFieldCustom>
  );
};

export const LuggageWeight = ({ values, setValues }: GridItemFieldProps) => (
  <ItemNumberField
    label="数量"
    name="l_volume"
    value={values.l_volume}
    setValues={setValues}
  />
);

export const Weight = ({ values, setValues }: GridItemFieldProps) => (
  <ItemNumberField
    label="重量(kg)"
    name="weight"
    value={values.weight}
    setValues={setValues}
    flgFloat={true}
  />
);

export const VehicleType = ({ values, setValues }: GridItemFieldProps) => {
  const { loading, vehicleTypes } = useContext(MstDataContext);
  if (loading) {
    return <div>Loading...</div>;
  }

  const options = vehicleTypes?.map((vt) => ({
    id: vt.id,
    label: vt.name,
  })) as List;

  options.push({ id: 0, label: 'その他' });

  return (
    <ItemSelect
      labelId="vt_id-label"
      name="vt_id"
      label="車種"
      value={Number(values.vt_id)}
      items={options}
      setValues={setValues}
    />
  );
};

export const Cars = ({ values, setValues }: GridItemFieldProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  const tax =
    SYSTEM?.tax.list.find((tax) => tax.id === values.tax_id)?.tax || 0;

  return (
    <GridItemNumberField
      label={'台数'}
      value={values.cars}
      name={'cars'}
      setValues={setValues}
      callback={(cars: number) => {
        let price = 0;
        let priceUnit = 0;
        if (values.price_unit) {
          priceUnit = values.price_unit;
          price = priceUnit * cars;
        } else if (values.price) {
          price = values.price;
          priceUnit = price / cars;
        }
        // 単価計算共通処理
        setPriceItem(price, priceUnit, cars, setValues, tax);
      }}
    ></GridItemNumberField>
  );
};

export const Memo = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextFieldCustom size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 4 }}>
    <Typography variant="h6">メモ</Typography>
    <SmallTextField
      props={{
        multiline: true,
        rows: 4,
        name: 'memo',
        value: values.memo,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e.target.value, e.target.name, setValues),
      }}
    />
  </GridItemTextFieldCustom>
);

export const InternalMemo = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextFieldCustom size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 4 }}>
    <Typography variant="h6">社内メモ</Typography>
    <SmallTextField
      props={{
        multiline: true,
        rows: 4,
        name: 'internal_memo',
        value: values.internal_memo,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e.target.value, e.target.name, setValues),
      }}
    />
  </GridItemTextFieldCustom>
);

export const ClientCompany = ({
  values,
  setValues,
  size = { xs: 12, lg: 3, xl: 3 },
}: GridItemFieldProps) => {
  const { loading, companies } = React.useContext(MstDataContext);

  if (loading) {
    return <Loading flg={true} />;
  }

  const options = companies?.map((company) => ({
    id: company.id,
    label: company.abbreviation,
  })) as List;

  const selectedOption = options?.find((option) => option.id === values.c_id);

  console.log('selectedOption', selectedOption);

  return (
    <GridItemTextFieldCustom size={size}>
      <Typography variant="h6">取引先</Typography>
      <FlexBox gapSize={1}>
        <SmallTextField
          label={''}
          props={{
            name: 'c_id',
            value: values.c_id || '',
            sx: { width: '100px' },
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeValues(Number(e.target.value), 'c_id', setValues);
            },
          }}
        />
        <AutocompleteSelect
          disableClearable={false}
          name="c_id"
          label={''}
          options={options}
          value={selectedOption || null}
          onChange={(
            e: React.SyntheticEvent<Element, Event>,
            newValue: ListItem
          ) => {
            if (!newValue) {
              handleChangeValues(null, 'c_id', setValues);
              return;
            }
            handleChangeValues(newValue.id, 'c_id', setValues);
          }}
        />
      </FlexBox>
    </GridItemTextFieldCustom>
  );
};

export const Person = ({ values, setValues }: GridItemFieldProps) => {
  const [options, setOptions] = useState<List>([]);

  useEffect(() => {
    if (!values.c_id) {
      setOptions([]);
    } else {
      getCompanyPersonList(values.c_id).then((res) => {
        setOptions(res.data as List);
      });
    }
  }, [values.c_id]);

  const selectedOption = options.find((option) => option.id === values.p_id);

  return (
    <GridItemTextFieldCustom>
      <AutocompleteSelect
        disableClearable={false}
        name="p_id"
        label={'担当者'}
        options={options}
        value={selectedOption || null}
        onChange={(
          e: React.SyntheticEvent<Element, Event>,
          newValue: ListItem
        ) => {
          if (!newValue) {
            handleChangeValues(null, 'p_id', setValues);
            return;
          }
          handleChangeValues(newValue.id, 'p_id', setValues);
        }}
      />
    </GridItemTextFieldCustom>
  );
};

function setPriceItem(
  price: number,
  price_unit: number,
  cars: number,
  setValues: any,
  tax: number
) {
  handleChangeValues(Math.round(price), 'price', setValues);
  handleChangeValues(Math.round(price_unit), 'price_unit', setValues);
  handleChangeValues(cars, 'cars', setValues);

  /** 税率はとりあえず固定 */
  handleChangeValues(Math.round(price) * tax, 'price_tax', setValues);
}

export const PriceUnitReference = ({
  values,
  setValues,
}: GridItemFieldProps) => (
  <ItemTextFiled
    label="参考単価"
    name="price_unit_reference"
    value={values.price_unit_reference}
    setValues={setValues}
  />
);

export const PriceUnit = ({ values, setValues }: GridItemFieldProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  const tax =
    SYSTEM?.tax.list.find((tax) => tax.id === values.tax_id)?.tax || 0;

  return (
    <GridItemNumberField
      label={'金額'}
      value={values.price_unit}
      name={'price_unit'}
      setValues={setValues}
      callback={(priveUnit: number) => {
        setPriceItem(
          Number(priveUnit) * values.cars,
          Number(priveUnit),
          values.cars,
          setValues,
          tax
        );
      }}
    ></GridItemNumberField>
  );
};

export const Price = ({ values, setValues }: GridItemFieldProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  const tax =
    SYSTEM?.tax.list.find((tax) => tax.id === values.tax_id)?.tax || 0;

  return (
    <GridItemNumberField
      label={'合計金額'}
      value={values.price}
      name={'price'}
      setValues={setValues}
      callback={(price: number) => {
        setPriceItem(
          Number(price),
          Number(price) / values.cars,
          values.cars,
          setValues,
          tax
        );
      }}
    ></GridItemNumberField>
  );
};
export const Tax = ({ values, setValues }: GridItemFieldProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  const options = SYSTEM?.tax.list.map((tax) => ({
    id: tax.id,
    label: tax.label,
  })) as List;

  return (
    <ItemSelect
      labelId="tax-label"
      name="tax_id"
      label="税率"
      value={values.tax_id}
      items={options}
      setValues={setValues}
    />
  );
};

export const PriceTax = ({ values, setValues }: GridItemFieldProps) => (
  <Grid item xs={12} lg={4} xl={3}>
    <Typography>{'税額'}</Typography>
    <Typography>{values.price_tax}</Typography>
  </Grid>
);

export const PriceTaxInclude = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextFieldCustom>
    <Typography>{values.price + values.price_tax}</Typography>
  </GridItemTextFieldCustom>
);

export const Status = ({
  values,
  setValues,
  size = undefined,
}: GridItemFieldProps) => {
  const options = projectEditStatus;

  if (values.status < projectStatusInstrunctionCreated.id) {
    return (
      <ItemSelect
        labelId="status-label"
        name="status"
        label="ステータス"
        value={values.status}
        items={options}
        setValues={setValues}
        size={size}
      />
    );
  } else {
    const valStatus = projectStatus.find(
      (status) => status.id === values.status
    );

    return (
      <GridItemTextFieldCustom>
        <SmallTextField
          label="ステータス"
          props={{
            name: 'status',
            value: valStatus?.label || '(未定義)',
            InputProps: {
              readOnly: true,
            },
          }}
        />
      </GridItemTextFieldCustom>
    );
  }
};

export function Kind({
  values,
  setValues,
  flgLabel = true,
}: GridItemFieldProps) {
  const { projectKinds, loading } = useContext(MstDataContext);
  if (loading) {
    return <div>Loading...</div>;
  }

  const options = projectKinds?.map((kind) => ({
    id: kind.id,
    label: kind.name,
  })) as List;

  if (!values.kind) {
    values.kind = 0;
  }

  const selectedOption = options.find((option) => option.id === values.kind);

  return (
    <ItemSelect
      labelId="kind-label"
      name="kind"
      label={flgLabel ? '区分' : ''}
      value={selectedOption ? Number(selectedOption.id) : 0}
      items={[...options, { id: 0, label: 'その他' }]}
      setValues={setValues}
    />
  );
}

export const LoadDate = ({
  values,
  setValues,
  flgLabel = true,
}: GridItemFieldProps) => (
  <DateInput
    type={'date'}
    label={flgLabel ? '積日時' : ''}
    name="load_datetime"
    value={values.load_datetime}
    onDateChange={(newDate: string) =>
      handleChangeValues(newDate, 'load_datetime', setValues)
    }
  />
);

export const UnloadDate = ({
  values,
  setValues,
  flgLabel = true,
}: GridItemFieldProps) => (
  <DateInput
    type={'date'}
    label={flgLabel ? '卸日時' : ''}
    name="unload_datetime"
    value={values.unload_datetime}
    onDateChange={(newDate: string) =>
      handleChangeValues(newDate, 'unload_datetime', setValues)
    }
  />
);

export const InCharge = ({ values, setValues }: GridItemFieldProps) => {
  const { users, roles, loading } = useContext(MstDataContext);
  const [operaters, setOperaters] = useState<List>([]);
  const [projectRoles, setProjectRoles] = useState<tRole[]>([]);

  useEffect(() => {
    if (!roles) {
      return;
    }

    // 案件入力を許可しているロールを取得
    const roleProject = roles.filter((role) => role.project === true);
    setProjectRoles(roleProject);
  }, [roles]);

  useEffect(() => {
    if (!users) {
      return;
    }

    // user.role_idがprojectRolesに含まれているユーザーを取得
    const roleUser = users.filter((user) =>
      projectRoles.map((role) => role.id).includes(user.role_id)
    );
    const temp = roleUser.map((user) => ({
      id: user.id,
      label: `${user.family_name} ${user.given_name}`,
    }));
    setOperaters(temp);
  }, [users, projectRoles]);

  const selectedOption = operaters.find(
    (operater) => operater.id === values.in_charge
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ItemSelect
      labelId="inCharge-label"
      name="in_charge"
      label="自社担当者"
      value={selectedOption ? Number(selectedOption.id) : 0}
      items={operaters}
      setValues={setValues}
    />
  );
};
