import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { FlexColumnBox } from 'atoms/Box';
import * as BtnCompany from 'components/company/Button';
import * as cCompany from 'components/mst/Company';
import { initAddress } from 'const/address';
import { initCompany } from 'const/comapny';
import { MstDataContext } from 'contexts/Mst';
import ContentFrame from 'frames/ContentsFrame';
import { Console_log } from 'functions';
import { getCompany, getCompanyPersons } from 'functions/api/mst';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { tErrMsg } from 'types';
import { tAddress } from 'types/address';
import { tCompany } from 'types/company';
import { initialPerson, tPerson } from 'types/mst';

const initialCustomAddress = {
  ...initAddress,
  flg_office: true,
};

export default function Main() {
  const { id } = useParams();
  const theme = useTheme();
  const [company, setCompany] = useState<tCompany>(initCompany);
  const [persons, setPersons] = useState<tPerson[]>([]);
  const [address, setAddress] = useState<tAddress>(initialCustomAddress);
  const [editPerson, setEditPerson] = useState<tPerson>(initialPerson);
  const [openPerson, setOpenPerson] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const { companies, addresses, fetchData, loading } =
    useContext(MstDataContext);

  const getData = (id: tCompany['id']) => {
    try {
      getCompany(id)
        .then((resCompany) => {
          if (resCompany.status !== 200)
            throw new Error('データ取得に失敗しました');
          //
          Console_log('resCompany.data', resCompany);
          setCompany(resCompany.data);

          // 人データ取得
          getCompanyPersons(1, 0, resCompany.data.id).then((res) => {
            if (res.status !== 200)
              throw new Error('人データ取得に失敗しました');
            //Console_log("persons.data", res.data);
            setPersons(res.data);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getData(Number(id));
    }
  }, [id]);

  useEffect(() => {
    // 住所情報をセット
    setAddress(
      addresses?.find((a) => a.id === company.office_a_id) ??
        initialCustomAddress
    );
  }, [company.office_a_id, addresses]);

  useEffect(() => {
    setCompany({ ...company, office_a_id: address.id });
  }, [address.id]);

  return (
    <ContentFrame
      HeadContent={<HeadContent id={id} company={company} />}
      MainContent={
        <MainContent
          values={company}
          setValues={setCompany}
          id={id}
          persons={persons}
          reloadData={getData}
        />
      }
      SubContent={<></>}
    />
  );
}

interface HeadContentProps {
  id: string | undefined;
  company: tCompany;
}
const HeadContent = ({ id, company }: HeadContentProps) => {
  return (
    <>
      {id ? (
        <>
          <BtnCompany.Update company={company} />
          <BtnCompany.Delete id={id as unknown as number} />
        </>
      ) : (
        <>
          <BtnCompany.Store company={company} />
        </>
      )}
    </>
  );
};

interface MainContentProps {
  values: tCompany;
  setValues: React.Dispatch<React.SetStateAction<tCompany>>;
  id: string | undefined;
  persons: tPerson[];
  reloadData: (id: tCompany['id']) => void;
}
const MainContent = ({
  values,
  setValues,
  id,
  persons,
  reloadData,
}: MainContentProps) => {
  const theme = useTheme();
  const [warningMsg, setWarningMsg] = useState<tErrMsg>({});
  const { companies } = useContext(MstDataContext);

  useEffect(() => {
    if (!companies || values.name === '' || values.abbreviation === '') {
      // ワーニングメッセージの会社名と略称を初期化
      setWarningMsg((prev) => {
        delete prev.会社名;
        delete prev.略称;
        return { ...prev };
      });
      return;
    }

    // 会社名が変更された場合の処理
    const likeCompanyName = companies
      .filter((c) => c.name.includes(values.name) && c.id !== values.id)
      .map((c) => c.name);

    // 略称が変更された場合の処理
    const likeAbbreviation = companies
      .filter(
        (c) =>
          c.abbreviation.includes(values.abbreviation) && c.id !== values.id
      )
      .map((c) => c.abbreviation);

    setWarningMsg((prev) => {
      const newWarnings = { ...prev };

      // 会社名のワーニングメッセージ設定
      if (likeCompanyName.length > 0) {
        newWarnings.会社名 = [
          `類似している会社名があります。[${likeCompanyName.join(', ')}]`,
        ];
      } else {
        delete newWarnings.会社名;
      }

      // 略称のワーニングメッセージ設定
      if (likeAbbreviation.length > 0) {
        newWarnings.略称 = [
          `同じ略称の会社があります。[${likeAbbreviation.join(', ')}]`,
        ];
      } else {
        delete newWarnings.略称;
      }

      return newWarnings;
    });

    //setWarningMsg({
  }, [values.id, values.name, values.abbreviation, companies]);

  const cbsPerson = () => {
    reloadData(values.id);
  };

  return (
    <FlexColumnBox>
      {Object.keys(warningMsg).map((key) => {
        return (
          <Typography key={`companyEdit-warning-${key}`} color="error">
            {warningMsg[key]}
          </Typography>
        );
      })}
      <Typography variant="h4">
        ID:
        <Typography component={'span'} variant="h4" sx={{ fontWeight: 700 }}>
          {values.id}
        </Typography>
      </Typography>
      <Grid container spacing={2}>
        <cCompany.Name values={values} setValues={setValues} />
        <cCompany.Abbreviation values={values} setValues={setValues} />
        <cCompany.AbbreviationKana values={values} setValues={setValues} />
        <cCompany.Tell values={values} setValues={setValues} />
        <cCompany.InvoiceNo values={values} setValues={setValues} />
      </Grid>

      <Grid container spacing={2}>
        <cCompany.ClosingDate values={values} setValues={setValues} />
        <cCompany.ClosingDateType values={values} setValues={setValues} />
        <cCompany.FlgBillingPerson values={values} setValues={setValues} />
      </Grid>

      <Grid container spacing={2}>
        <cCompany.PostNumber values={values} setValues={setValues} />
        <cCompany.Address1 values={values} setValues={setValues} />
        <cCompany.Address2 values={values} setValues={setValues} />
      </Grid>
      {id && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              sx={{ display: 'flex', flexFlow: 'row', gap: theme.spacing(2) }}
            >
              <Typography variant="h5">人リスト</Typography>
              <BtnCompany.PersonEdit
                label={'新規'}
                customPerson={{
                  ...initialPerson,
                  c_id: id as unknown as number,
                }}
                cbs={cbsPerson}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ maxWidth: '680px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>名称</TableCell>
                    <TableCell>電話番号</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {persons.map((person: any) => {
                    return (
                      <TableRow key={`companyEdit-persons-${person.id}`}>
                        <TableCell>{`${person.family_name} ${person.given_name}`}</TableCell>
                        <TableCell>{`${person.tell}`}</TableCell>
                        <TableCell>
                          <BtnCompany.PersonEdit
                            label="編集"
                            customPerson={person}
                            cbs={cbsPerson}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </FlexColumnBox>
  );
};
