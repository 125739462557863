import { Box, Typography } from '@mui/material';
import { tErrMsg } from 'types/index';

export const ErrMsgBox = ({ errMsg }: { errMsg: tErrMsg }) => {
  return (
    <Box style={{ overflowY: 'auto' }}>
      {Object.keys(errMsg).map((key) => (
        <Box key={key} style={{ marginBottom: '8px' }}>
          {/*
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {key}
          </Typography>
          */}
          <ul>
            {errMsg[key].map((message, index) => (
              <li key={index}>
                <Typography variant="body2" color="error">
                  {message}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      ))}
    </Box>
  );
};

export const MsgBox = ({
  errMsg,
  warningMsg,
}: {
  errMsg: tErrMsg;
  warningMsg: tErrMsg;
}) => {
  return (
    <Box style={{ maxHeight: '150px', overflowY: 'auto' }}>
      {Object.keys(warningMsg).map((key) => (
        <Box key={key} style={{ marginBottom: '8px' }}>
          {/*
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          {key}
        </Typography>
        */}
          <ul>
            {warningMsg[key].map((message, index) => (
              <li key={index}>
                <Typography variant="body2" style={{ color: '#4caf50' }}>
                  {message}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      ))}
      {Object.keys(errMsg).map((key) => (
        <Box key={key} style={{ marginBottom: '8px' }}>
          {/*
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {key}
          </Typography>
          */}
          <ul>
            {errMsg[key].map((message, index) => (
              <li key={index}>
                <Typography variant="body2" color="error">
                  {message}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      ))}
    </Box>
  );
};
