import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FlexColumnBox } from 'atoms/Box';
import * as BtnAddress from 'components/address/Button';
import { GridItemTextField } from 'components/GridItem';
import * as ComponentAddress from 'components/mst/Address';
import { initAddress } from 'const/address';
import ContentsFrame from 'frames/ContentsFrame';
import { Console_log } from 'functions';
import { getAddress } from 'functions/api/mst';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { tAddress } from 'types/address';

export default function Main() {
  const { id } = useParams();
  const [address, setAddress] = useState<tAddress>(initAddress);

  Console_log('id', id);

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        // 住所データ取得
        getAddress(id).then((res) => {
          if (res.status !== 200)
            throw new Error('住所データ取得に失敗しました');
          Console_log('addresses.data', res.data);
          setAddress(res.data);
        });
      } catch (error) {
        alert('住所データ取得に失敗しました');
        console.error(error);
      }
    };
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  return (
    <ContentsFrame
      HeadContent={<HeadContent id={id} values={address} />}
      MainContent={<Details values={address} setValues={setAddress} />}
      SubContent={<></>}
    />
  );
}

interface HeadContentProps {
  id: string | undefined;
  values: tAddress;
}
const HeadContent = ({ id, values }: HeadContentProps) => {
  return (
    <>
      {id ? (
        <>
          <BtnAddress.Update data={values} />
          <BtnAddress.Delete id={values.id} />
        </>
      ) : (
        <>
          <BtnAddress.Store data={values} />
        </>
      )}
    </>
  );
};

interface DetailsProps {
  values: tAddress;
  setValues: React.Dispatch<React.SetStateAction<tAddress>>;
}
const Details = ({ values, setValues }: DetailsProps) => {
  return (
    <FlexColumnBox>
      <Grid container spacing={2}>
        <GridItemTextField
          name={'name'}
          label="名称"
          value={values.name}
          handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValues({ ...values, name: e.target.value })
          }
        />

        <GridItemTextField
          name={'post_number'}
          label="郵便番号"
          value={values.post_number}
          handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValues({ ...values, post_number: e.target.value })
          }
        />
        <Grid item xs={12}></Grid>

        <ComponentAddress.Prefectures values={values} setValues={setValues} />
        <ComponentAddress.City values={values} setValues={setValues} />
        <ComponentAddress.Street values={values} setValues={setValues} />
        <ComponentAddress.Building values={values} setValues={setValues} />

        <Grid item xs={12}></Grid>

        <GridItemTextField
          name={'tell'}
          label="電話番号"
          value={values.tell}
          handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValues({ ...values, tell: e.target.value })
          }
        />

        <GridItemTextField
          name={'fax'}
          label="FAX"
          value={values.fax}
          handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValues({ ...values, fax: e.target.value })
          }
        />

        <Grid item xs={12}></Grid>

        <Grid item xs={12} lg={3}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                name={'flg_load'}
                checked={values.flg_load}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValues({ ...values, flg_load: e.target.checked })
                }
              />
            }
            label="積地"
          />
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                name={'flg_unload'}
                checked={values.flg_unload}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValues({ ...values, flg_unload: e.target.checked })
                }
              />
            }
            label="卸地"
          />
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                name={'flg_office'}
                checked={values.flg_office}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValues({ ...values, flg_office: e.target.checked })
                }
              />
            }
            label="事務所"
          />
        </Grid>
      </Grid>
    </FlexColumnBox>
  );
};
