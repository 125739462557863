import { API_URL, create } from 'functions/axios';

// 認証チェック関数（リダイレクトなどの副作用は行わない）
export const isAuthenticated = async (): Promise<boolean> => {
  const ins = create();

  try {
    const res = await ins.get(API_URL.base + '/token-check');
    return res.status === 200; // トークンが有効ならtrue
  } catch (error) {
    console.error('Error during authentication check:', error);
    return false; // エラーの場合はfalse
  }
};
