import { PrimaryButton } from 'atoms/Button';
import ErrModal from 'components/error/Modal';
import * as apiMst from 'functions/api/mst';
import { changeToState } from 'functions/error/';
import { useState } from 'react';
import { tErrMsg } from 'types/index';
import { tSelfInformation } from 'types/mst';

interface StoreProps {
  data: tSelfInformation;
  cbs?: (data: tSelfInformation) => void;
  cbe?: (error: any) => void;
}
export const Store = ({ data, cbs, cbe }: StoreProps) => {
  const [showModal, setShowModal] = useState(false);
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const handleClick = () => {
    apiMst
      .upOrCrSelfInfomation(data)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ登録に失敗しました');
        alert('データを登録しました');
        if (cbs) cbs(res.data);
      })
      .catch((err) => {
        setValidationMsg(changeToState(err));
        setShowModal(true); // エラーがある場合モーダル表示
        if (cbe) cbe(err);
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton label="登録" onClick={handleClick} />
    </>
  );
};

export const Update = ({ data, cbs, cbe }: StoreProps) => {
  const [showModal, setShowModal] = useState(false);
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const handleClick = () => {
    apiMst
      .storeSelfInfomation(data)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ更新に失敗しました');
        alert('データを更新しました');
        if (cbs) cbs(res.data);
      })
      .catch((err) => {
        console.log('err', err);
        setValidationMsg(changeToState(err));
        setShowModal(true); // エラーがある場合モーダル表示
        if (cbe) cbe(err);
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton label="更新" onClick={handleClick} />
    </>
  );
};
