import log from 'functions/logger';
import {
  convertLocalDateToUTC,
  isSameDate,
  strDateOrigin,
} from 'functions/time';
import { tDailyMemo, tDailyMemoCalender } from 'types/dailymemo';

export function convertToDailyMemoCalendar(
  dailyMemos: tDailyMemo[]
): tDailyMemoCalender {
  const calendar: tDailyMemoCalender = {};

  dailyMemos.forEach((memo) => {
    const date = strDateOrigin(memo.datetime);
    if (!calendar[date]) {
      calendar[date] = [];
    }
    calendar[date].push(memo);
  });

  return calendar;
}

export function getDailyMemosToDate(
  dailyMemos: tDailyMemo[],
  date: string
): tDailyMemo[] {
  const obj: tDailyMemo[] = [];

  dailyMemos.forEach((memo) => {
    if (isSameDate(convertLocalDateToUTC(date), memo.datetime)) {
      obj.push(memo);
    }
  });

  log.debug('getDailyMemosToDate obj.length', obj.length);

  return obj;
}
