import { MstDataContext } from 'contexts/Mst';
import {
  defaultValidationRules,
  tValidationRules,
  validateCheck,
} from 'functions/validationMessage';
import { useContext } from 'react';
import { tErrMsg } from 'types/index';
import { tInstruction } from 'types/instruction';

/**
 * 自社手配の場合のバリデーション関数を返すカスタムフック
 * @param instruction
 * @returns
 */
export function useValidate() {
  const { SYSTEM } = useContext(MstDataContext);

  const validate = (instruction: tInstruction): tErrMsg => {
    let errMsg: tErrMsg = { method: [], prefectures: [], city: [] };

    switch (instruction.tm_id) {
      case SYSTEM?.tranMethod.own.id:
        errMsg = validateOwn(instruction, errMsg);
        break;
      case SYSTEM?.tranMethod.charter.id:
        errMsg = validateCharter(instruction, errMsg);
        break;
      default:
        errMsg = common(instruction, errMsg);
        break;
    }

    Object.keys(errMsg).forEach((key) => {
      if (errMsg[key].length === 0) {
        delete errMsg[key];
      }
    });

    return errMsg;
  };

  return { validate };
}

/**
 * 自社手配の場合のバリデーション
 * @param instruction
 * @returns
 */
function validateOwn(instruction: tInstruction, errMsg: tErrMsg): tErrMsg {
  errMsg = common(instruction, errMsg);

  const rulesRequired: tValidationRules = {
    ...defaultValidationRules,
    required: true,
  };

  // ドライバー
  errMsg.v_id = validateCheck(
    'ドライバー',
    instruction.user_id,
    'number',
    rulesRequired
  );

  if (!instruction.v_id) {
    if (!errMsg.user_id) errMsg.user_id = [];
    errMsg.user_id.push('ドライバーに車両が設定されていません');
  }

  return errMsg;
}

/**
 * 傭車の場合のバリデーション
 * @param instruction
 * @returns
 */
function validateCharter(instruction: tInstruction, errMsg: tErrMsg): tErrMsg {
  errMsg = common(instruction, errMsg);

  return errMsg;
}

function common(instruction: tInstruction, errMsg: tErrMsg): tErrMsg {
  const rulesRequired: tValidationRules = {
    ...defaultValidationRules,
    required: true,
  };

  // 輸送手段
  errMsg.method = validateCheck(
    '輸送方法',
    instruction.tm_id,
    'number',
    rulesRequired
  );

  // 都道府県（発地点）
  errMsg.method = validateCheck(
    '都道府県（発地点）',
    instruction.start_prefectures,
    'string',
    rulesRequired
  );

  // 市区町村（発地点）
  errMsg.method = validateCheck(
    '市区町村（発地点）',
    instruction.start_city,
    'string',
    rulesRequired
  );

  // 着日時
  const rulesEndDatetime: tValidationRules = {
    ...defaultValidationRules,
    dateBelow: instruction.start_datetime,
  };
  errMsg.method = validateCheck(
    '着日時',
    instruction.end_datetime,
    'string',
    rulesEndDatetime
  );

  // 都道府県（着地点）
  errMsg.method = validateCheck(
    '都道府県（着地点）',
    instruction.end_prefectures,
    'string',
    rulesRequired
  );

  // 市区町村（着地点）
  errMsg.method = validateCheck(
    '市区町村（着地点）',
    instruction.end_city,
    'string',
    rulesRequired
  );

  return errMsg;
}
