import { Box } from '@mui/system';
import { SecondButton } from 'atoms/Button';
import Modal from 'atoms/Modal';
import { ErrMsgBox } from 'components/error/Detail';
import React from 'react';
import { tErrMsg } from 'types/index';

interface Props {
  errMsg: tErrMsg;
  setErrMsg: React.Dispatch<React.SetStateAction<tErrMsg>>;
}

export default function ErrModal({ errMsg, setErrMsg }: Props) {
  return (
    <Modal
      width="xl"
      open={Object.keys(errMsg).length > 0}
      onClose={() => {
        setErrMsg({});
      }}
      title="エラー"
      actions={
        <SecondButton
          label="閉じる"
          onClick={() => {
            setErrMsg({});
          }}
        />
      }
    >
      <Box sx={{ padding: 2 }}>
        <ErrMsgBox errMsg={errMsg} />
      </Box>
    </Modal>
  );
}
