import { Grid } from '@mui/material';
import { FlexColumnBox } from 'atoms/Box';
import { GridItemTextField } from 'components/GridItem';
import * as BtnLuggage from 'components/luggage/Button';
import ContentsFrame from 'frames/ContentsFrame';
import { getLuggage } from 'functions/api/mst';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { tLuggage } from 'types/mst';

export default function Main() {
  const { id } = useParams();
  const [luggage, setLuggage] = useState<tLuggage>({
    id: 0,
    name: '',
    unit: '',
    volume: 0,
    cm_ids: [],
  });

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        //await getCsrf();
        getLuggage(id)
          .then((res) => {
            if (res.status !== 200) throw new Error('データ取得に失敗しました');
            setLuggage(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        console.error(error);
      }
    };
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  return (
    <ContentsFrame
      HeadContent={<HeadContent id={id} values={luggage} />}
      MainContent={<Details values={luggage} setValues={setLuggage} />}
      SubContent={<></>}
    />
  );
}

interface HeadContentProps {
  id: string | undefined;
  values: tLuggage;
}
const HeadContent = ({ id, values }: HeadContentProps) => {
  return (
    <>
      {id ? (
        <>
          <BtnLuggage.Update data={values} />
          <BtnLuggage.Delete id={id as unknown as number} />
        </>
      ) : (
        <>
          <BtnLuggage.Store data={values} />
        </>
      )}
    </>
  );
};

interface DetailsProps {
  values: tLuggage;
  setValues: React.Dispatch<React.SetStateAction<tLuggage>>;
}
const Details = ({ values, setValues }: DetailsProps) => {
  return (
    <FlexColumnBox>
      <Grid container spacing={2}>
        <GridItemTextField
          name={'name'}
          label="名称"
          value={values.name}
          handleChangeValues={(e) =>
            setValues({ ...values, name: e.target.value })
          }
        />

        <GridItemTextField
          name={'unit'}
          label="単位"
          value={values.unit}
          handleChangeValues={(e) =>
            setValues({ ...values, unit: e.target.value })
          }
        />

        <GridItemTextField
          name={'volume'}
          label="容量"
          value={values.volume}
          handleChangeValues={(e) =>
            setValues({ ...values, volume: Number(e.target.value) })
          }
        />
      </Grid>
    </FlexColumnBox>
  );
};
