import { PrimaryButton } from 'atoms/Button';
import CreateInvoiceModal from 'components/customInvocie/CrateInvoiceModal';
import PDFModal from 'components/invoice/PDFModal';
import { MstDataContext } from 'contexts/Mst';
import { getCompany } from 'functions/api/mst';
import {
  storeCustomInvoiceEdit,
  storeInvoice,
  updateCustomInvoiceEdit,
} from 'functions/api/paperwork';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tCompany } from 'types/company';
import { tCustomInvoiceEdit, tInvoice, tInvoiceDetail } from 'types/invoice';

export const NavigateInput = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/paperwork/custom-invoice/input');
  };
  return <PrimaryButton label="新規" onClick={handleClick} />;
};

interface InsertProps {
  callbackSuccess?: ((data: tCustomInvoiceEdit) => void) | undefined;
  callbackError?: (() => void) | undefined;
  data: tCustomInvoiceEdit;
}
export const Insert = ({
  callbackSuccess = undefined,
  callbackError = undefined,
  data,
}: InsertProps) => {
  const handleClick = () => {
    if (data.id) {
      // idを削除
      delete data.id;
    }
    storeCustomInvoiceEdit(callbackSuccess, callbackError, data);
  };

  return <PrimaryButton label="新規登録" onClick={handleClick} />;
};

export const Update = ({
  callbackSuccess,
  callbackError,
  data,
}: InsertProps) => {
  const handleClick = () => {
    updateCustomInvoiceEdit(callbackSuccess, callbackError, data);
  };

  return <PrimaryButton label="更新" onClick={handleClick} />;
};

interface MakeInvoiceProps {
  callbackSuccess?: (() => void) | undefined;
  callbackError?: (() => void) | undefined;
  data: tCustomInvoiceEdit[];
}

export const MakeInvoice = ({
  callbackSuccess = undefined,
  callbackError = undefined,
  data,
}: MakeInvoiceProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  const [invoice, setInvoice] = useState<tInvoice>({
    id: 0,
    invoice_number: '',
    title: '',
    print_date: null,
    total_amount: 0,
    tax: 0,
    billing_amount: 0,
    detail_count: 0,
    c_invoice_no: '',
    c_id: 0,
    c_name: '',
    c_postal_code: '',
    c_address1: '',
    c_address2: '',
    pj_in_charge_name: '',
  });
  const [details, setDetails] = useState<tInvoiceDetail[]>([]);
  const [open, setOpen] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [storeData, setStoreData] = useState<tInvoice>();

  const handleClick = async () => {
    // 会社マスタから会社情報を取得
    const res = await getCompany(data[0].c_id);
    const company: tCompany = res.data;

    let totalAmount = 0;
    let totalTax = 0;

    // tCustomInvoiceEditからtInvoiceDetailに変換
    const details: tInvoiceDetail[] = data.map((d) => {
      totalAmount += d.price;
      totalTax += d.price_tax;

      return {
        domain: d.domain,

        cie_id: d.id,
        date: d.date,
        car_type: null,
        car_number: null,
        load_name: null,
        unload_name: null,
        luggage_name: null,
        transport_quantity: null,
        transport_unit: null,
        unit_price: d.unit_price,
        quantity: d.quantity,
        price: d.price,
        extra_amount: 0,
        tax_id: d.tax_id,
        price_tax: d.price_tax,
        kbn: SYSTEM?.invoice.kbn.custom || -1,
        memo: d.memo,
      };
    });
    setDetails(details);

    setInvoice((prev) => ({
      ...prev,
      title: '個別請求',
      print_date: null,
      total_amount: totalAmount,
      tax: totalTax,
      billing_amount: totalAmount + totalTax,
      detail_count: details.length,

      c_invoice_no: company.invoice_no,
      c_id: data[0].c_id,
      c_name: company.name,
      c_postal_code: company.post_number,
      c_address1: company.address1,
      c_address2: company.address2,
      pj_in_charge_name: null,
    }));

    setOpen(true);
  };

  const callbackCreateInvoice = () => {
    const callbackStore = (data: tInvoice) => {
      setStoreData(data);
      // 発行するかどうかの確認
      if (
        window.confirm(
          '請求書データ作成に成功しました。¥nこのまま請求書を発行しますか？'
        )
      ) {
        setPdfOpen(true);
      }
      if (callbackSuccess) {
        callbackSuccess();
      }
    };
    const callbackError = () => {
      alert('請求書データ作成に失敗しました。');
    };
    storeInvoice({ invoice, details }, callbackStore, callbackError);
  };
  return (
    <>
      <CreateInvoiceModal
        invoice={invoice}
        setInvoice={setInvoice}
        open={open}
        setOpen={setOpen}
        callbackSuccess={callbackCreateInvoice}
      />
      <PDFModal
        sheetName="個別請求"
        invoiceId={storeData?.id || 0}
        open={pdfOpen}
        setOpen={setPdfOpen}
      />
      <PrimaryButton label="請求書作成" onClick={handleClick} />
    </>
  );
};
